import { genericGet } from "../utils/backendRequest";
import { endpointsQuery } from "../utils/endpoints";
import { getBrandId } from "../utils/getAppVariantId";

const brandId = getBrandId();

const AudioServices = {
  getAudios: (lastVisible, region) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await genericGet(
          endpointsQuery.getAudiosList.replace("{brandId}", brandId),
          region
        );

        const result = {
          audios: response.data.map((a) => ({ id: a.id, data: a })),
          lastVisible: response.data[response.data.length - 1],
        };
        resolve(result);
      } catch (error) {
        reject(error.message);
      }
    });
  },
  getAudio: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await genericGet(
          endpointsQuery.getAudio.replace("{id}", id)
        );
        const data = response;
        resolve(data);
      } catch (error) {
        reject(error.message);
      }
    });
  },
};

export default AudioServices;
