import React, { Component, createContext } from "react";
import { NotifyProvider } from "./NotificationContext";
import { toast } from "react-toastify";

export const NotificationContext = createContext();

class NotificationProvider extends Component {
  state = {
    message: "",
    answer: null,
  };

  setAnswer = (answer) => {
    this.setState({ answer });
  };

  Msg = ({ closeToast }) => {
    return (
      <div className="content-toast">
        <span className="text-toast">{this.state.message}</span>
        <div className="btns-container">
          <button className="confirm-btn" onClick={() => this.setAnswer(true)}>
            Yes
          </button>
          <button className="cancel-btn" onClick={() => this.setAnswer(false)}>
            No
          </button>
        </div>
      </div>
    );
  };

  // success', 'warning', 'error', confirmation
  runNotification = (message, type) => {
    if(message.message){
      message = message.message;
    }
    if (type === "confirmation") {
      this.setState({ message });
      toast(this.Msg, {
        autoClose: false,
        draggable: false,
        type: "default",
        progress: false,
        closeOnClick: true,
        hideProgressBar: true,
        position: "top-center",
      });
    } else {
      toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        type: type,
      });
    }
  };

  render() {
    return (
      <NotifyProvider
        value={{
          runNotification: this.runNotification,
          answer: this.state.answer,
          setAnswer: this.setAnswer,
        }}
      >
        {this.props.children}
      </NotifyProvider>
    );
  }
}

export default NotificationProvider;
