import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SubscribeModal } from '../tribes-media-ui/components'
import { withLanguage } from '../providers/LanguageContext'
import { useModals } from '../providers/ModalsContext'
import { withNotification } from '../providers/NotificationContext'
import { withContext } from '../providers/UserContext'
import UserServices from '../services/UserServices'
import trackEvent, { FIREBASE_EVENTS } from '../utils/trackEvent'
import { Logo } from './../resources/Logos'
import Env from './../.env.js'
import { Spinner } from 'react-bootstrap'
import {
  logBeginCheckout,
  logPurchase,
  logScreenView,
  logViewPromotion,
} from '../utils/analyticsEvents'
import { log } from '../utils/functions'

function Subscription(props) {
  const {
    region,
    currentLanguage,
    translate,
    loadUserData,
    user,
    runNotification,
  } = props
  const [loading, setLoading] = React.useState(false)
  const [promo, setPromo] = useState()
  const [globalLoading, setGlobalLoading] = useState(false)
  const [plans, setPlans] = React.useState([])
  const history = useHistory()
  const { openSubscription, onToggle, data } = useModals()

  React.useEffect(() => {
    if (openSubscription && plans.length) {
      const annualPrice = plans.find((e) => e.recurring.interval === 'year')
      const monthlyPrice = plans.find((e) => e.recurring.interval === 'month')

      logViewPromotion({
        monthlyPrice: insertDecimalPoints(monthlyPrice.unit_amount_decimal),
        annualPrice: insertDecimalPoints(annualPrice.unit_amount_decimal),
        content:
          data.contentTitle && data.contentType
            ? {
                title: data.contentTitle,
                type: data.contentType,
              }
            : null,
      })
    }
  }, [openSubscription, data, plans])

  useEffect(() => {
    if (plans.length) {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search)
      const sessionId = query.get('session_id')
      const plan = query.get('plan')
      if (plan) {
        setGlobalLoading(true)
        onSubmit(plan, sessionId)
      }
    }
  }, [plans])

  React.useEffect(() => {
    async function loadPlans() {
      setGlobalLoading(true)
      try {
        const _plans = await UserServices.getPlans(region)
        setPlans(_plans.reverse())
        setGlobalLoading(false)
      } catch (e) {
        setGlobalLoading(false)
        toast.error('Something went wrong, contact our support')
        console.log(e)
      }
    }

    // logViewPromotion({})

    loadPlans()
  }, [])

  function getAmplitudeBaseParams(planSelected) {
    const interval = plans.find((e) => e.id === planSelected).recurring.interval
    const baseAmplitudeParams = {
      packageId: planSelected,
      price: plansList.find((e) => e.id === planSelected).label,
      packageName: interval === 'year' ? 'annual' : 'monthly',
      paymentPlatform: 'stripe',
      ...(data.contentTitle && {
        contentTitle: data.contentTitle,
      }),
      ...(data.contentType && {
        contentType: data.contentType,
      }),
    }
    return baseAmplitudeParams
  }

  async function onSubmit(planSelected, sessionId, pPromo) {
    setLoading(true)
    const baseAmplitudeParams = getAmplitudeBaseParams(planSelected)

    const interval = plans.find((e) => e.id === planSelected).recurring.interval

    try {
      await UserServices.subscribe(
        baseAmplitudeParams.packageName,
        sessionId,
        promo || pPromo
      )
      await UserServices.saveStripeId(sessionId, interval)
      setLoading(false)
      setGlobalLoading(false)
      onClose()

      logPurchase({
        value: plansList.find((e) => e.id === planSelected).value,
        currency:
          plansList.find((e) => e.id === planSelected).currency_code || 'USD',
        packageID: planSelected,
        packageName: interval === 'year' ? 'annual' : 'monthly',
        paymentPlatform: 'stripe',
        coupon: promo ? promo : '',
      })

      loadUserData()

      return

      history.push('/success')
      window.dataLayer.push({ event: 'virtualPageview' })
    } catch (e) {
      logScreenView({
        name: `Payment Declined - ${planSelected} - ${
          interval === 'year' ? 'annual' : 'monthly'
        } - ${
          plansList.find((e) => e.id === planSelected).label
        } - Stripe - ${JSON.stringify(e.message)}`,
      })

      runNotification(e?.raw?.message || translate('errorMsg'), 'error')
      setLoading(false)
      setGlobalLoading(false)
    }
  }

  function onProcessStarted(planSelected) {
    const interval = plans.find((e) => e.id === planSelected).recurring.interval

    logBeginCheckout({
      value: plansList.find((e) => e.id === planSelected).value,
      currency:
        plansList.find((e) => e.id === planSelected).currency_code || 'USD',
      packageID: planSelected,
      packageName: interval === 'year' ? 'annual' : 'monthly',
    })
  }

  function onClose() {
    if (history.location?.hash === '#openSubscription') {
      history.replace(history.location.pathname)
    }
    onToggle('openSubscription')
  }

  function getPeriod(interval) {
    switch (interval) {
      case 'month':
        return `/ ${translate('monthly').toLowerCase()}`
      case 'year':
        return `/ ${translate('yearly').toLowerCase()}`
      default:
        return ''
    }
  }

  function insertDecimalPoints(s) {
    var decimal = s[s.length - 1] + s[s.length - 2]
    var number = s.slice(0, -2)
    var res = number + '.' + decimal
    return res
  }

  const plansList = plans.map((plan) => ({
    label: plan[currentLanguage?.toLowerCase()],
    discountLabel: plan[`subtitle${currentLanguage?.toUpperCase()}`],
    id: plan.id,
    hasCoupon: plan.recurring.interval === 'year',
    value: insertDecimalPoints(plan.unit_amount_decimal),
  }))

  if (openSubscription && globalLoading)
    return (
      <div
        className="absolute flex justify-center items-center my-5 w-screen h-screen z-10"
        style={{ background: 'rgba(0,0,0,0.7)' }}
      >
        <Spinner animation="border" />
      </div>
    )
  return (
    <SubscribeModal
      region={region}
      open={openSubscription}
      onClose={onClose}
      onSubmit={onSubmit}
      apiKey={Env.stripe_api_key}
      disabled={loading}
      onProcessStarted={onProcessStarted}
      autoCoupon={translate('autoCoupon')}
      validateCoupon={(coupon) => {
        const validCoupon = translate(coupon)
        validCoupon && setPromo(coupon)
        return validCoupon
      }}
      labels={{
        or: translate('or'),
        subTitle: translate('startSubscriptionTitle'),
        alreadyMember: translate('alreadyAMember'),
        login: translate('login'),
        cardNumber: translate('cardNumber'),
        cardCVC: translate('cardCVC'),
        cardExpiration: translate('cardExpiration'),
        coupon: translate('coupon'),
        confirm: translate('confirmBtn'),
        discount: translate('startSubscriptionPlan1Subtitle'),
        details: (
          <p
            style={{ fontWeight: 500 }}
            dangerouslySetInnerHTML={{
              __html: translate('startSubscriptionSubtitle'),
            }}
          />
        ),
        termsAndConditions: (
          <>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://cdn3.themyst.com/TermsandConditions.html"
              style={{ color: 'white' }}
            >
              {translate('termsAndUse')}
            </a>
            {' & '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://cdn3.themyst.com/privacy.html"
              style={{ color: 'white' }}
            >
              {translate('privacyPolicy')}
            </a>
          </>
        ),
      }}
      plans={plansList}
      color="secondary"
      logo={Logo}
      {...(!user.auth && {
        onClickLogin: () => onToggle('openLogin'),
      })}
    />
  )
}

export default withNotification(withLanguage(withContext(Subscription)))
