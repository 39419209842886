import classNames from "classnames";
import { withContext } from "../providers/UserContext";

function FormStepCounter({ state, translate, totalSteps }) {
  const [step, setStep] = state;

  const renderStepIndicator = (pStep, active) => (
    <span
      className={classNames(
        "text-white font-bold border-2 rounded-full w-8 h-8 text-center mx-2",
        { "primary-border-color": active }
      )}
      style={{ borderColor: !active && "#1A1A1A" }}
    >
      {pStep}
    </span>
  );

  return (
    <div className="flex items-center">
      <span className="primary-color">{translate("step")}</span>
      {renderStepIndicator(step + 1, true)}
      <span className="primary-color">{translate("of")}</span>
      {renderStepIndicator(totalSteps)}
    </div>
  );
}

export default withContext(FormStepCounter);
