import React from 'react'
import Input from '../../Input'
import Button from '../../Button'
import { validate } from './utils'
import isEmpty from 'lodash/isEmpty'
import { useFormik } from 'formik'
import Env from '../../../../.env.js'

function FormFlow(props) {
  const { disabled, language, final, labels, colorFlow, btnFlow } = props

  const changeSet = (values) => {
    final(values)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      repeatPassword: '',
    },
    validate: validate(language),
    onSubmit: (values) => {
      changeSet(values)
    },
  })

  return (
    <>
      <div className={`create ${colorFlow}`}>{labels.createAccount}</div>
      <div className={`join ${colorFlow}`}>{labels.join}</div>
      <form className="box-form" onSubmit={formik.handleSubmit}>
        <Input
          containerClassname="mb-3"
          className="w-full force-black"
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email}
          placeholder={labels.email}
        />
        <div className="password">
          <Input
            placeholder={labels.password}
            containerClassname="mb-3"
            className="force-black"
            id="password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
          />
          <Input
            placeholder={labels.repeatPassword}
            containerClassname="mb-3"
            className="force-black"
            id="repeatPassword"
            name="repeatPassword"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.repeatPassword}
            error={
              formik.touched.repeatPassword && formik.errors.repeatPassword
            }
          />
        </div>
        <Button
          label={labels.confirm}
          className={`uppercase w-full ${btnFlow} ${
            Env.env === 'myst' && 'text-white'
          }`}
          type="submit"
          disabled={!isEmpty(formik.errors) || disabled || !formik.dirty}
        />
      </form>
    </>
  )
}

export default FormFlow
