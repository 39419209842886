import { genericGet } from "../utils/backendRequest.ts";
import { endpointsQuery } from "../utils/endpoints.ts";
import { getBrandId } from "../utils/getAppVariantId.ts";

const brandId = getBrandId();

const SerieServices = {
  getSeries: (lastVisible, region) => {
    return new Promise(async (resolve, reject) => {
      try {
        const seriesResponse =
          brandId === 3
            ? await genericGet(
                endpointsQuery.getShortsList.replace("{brandId}", brandId),
                region
              )
            : await genericGet(
                endpointsQuery.getSeriesList.replace("{brandId}", brandId),
                region
              );
        const result = {
          series: seriesResponse.data.map((s) => ({ id: s.id, data: s })),
          lastVisible: seriesResponse.data[seriesResponse.data.length - 1],
        };
        resolve(result);
      } catch (error) {
        reject(error.message);
      }
    });
  },
  getSerie: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await genericGet(
          endpointsQuery.getMovie.replace("{id}", id)
        );
        const data = response;
        resolve(data);
      } catch (error) {
        reject(error.message);
      }
    });
  },
};

export default SerieServices;
