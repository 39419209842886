import React, { Component, createContext } from 'react'
import LanguageServices from '../services/LanguageServices'
import UserServices from '../services/UserServices'
import { log } from '../utils/functions'
import { LangProvider } from './LanguageContext'

export const LanguageContext = createContext({ region: null })

class LanguageProvider extends Component {
  state = {
    region: undefined,
    ip: undefined,
    languages: undefined,
  }

  componentDidMount = async () => {
    try {
      const [location] = await Promise.all([UserServices.getClientLocation()])
      const [languages] = await Promise.all([
        LanguageServices.getLanguagesData(),
      ])

      this.setState({
        region: location.country_code,
        ip: location.ip,
        languages,
      })
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    return (
      <LangProvider
        value={{
          region: this.state.region,
          ip: this.state.ip,
          languages: this.state.languages,
        }}
      >
        {this.props.children}
      </LangProvider>
    )
  }
}

export default LanguageProvider
