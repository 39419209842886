import { firestore } from './FirebaseService';

const CastServices = {

	getCastDetail: (name) => {
		return new Promise(async (resolve, reject) => {
			try {
				const detailsReq = await firestore.collection('castDetails').doc(name).get()
				resolve(detailsReq.exists ? detailsReq?.data() : null)
			} catch (error) {
		    reject(error.message);
		  }
		})
	},

}

export default CastServices;