import { firestore } from '../services/FirebaseService'

export default async function isServiceAvailable(country) {
  if (!country) return false

  if (process.env.NODE_ENV === 'development') return true

  const available = await firestore
    .doc(`GV/regions`)
    .get()
    .then((doc) => doc?.data().default.includes(country))
    .catch(() => false)

  return available
}
