import React from 'react'
import { useLocation } from 'react-router-dom'
import usePrevious from '../hooks/usePrevious'
import { OnboardingModal } from '../tribes-media-ui/components'
import { withContext } from '../providers/UserContext'
import { withLanguage } from '../providers/LanguageContext'
import { Logo } from './../resources/Logos'
import trackEvent, { FIREBASE_EVENTS } from '../utils/trackEvent'

function Onboarding(props) {
  const { user, translate } = props
  const { hash, search } = useLocation()
  const [openModal, setOpenModal] = React.useState(false)
  const prevAuth = usePrevious(user.auth)

  React.useEffect(() => {
    const shouldOpenModal =
      true ||
      hash === '#openLogin' ||
      hash === '#openSignup' ||
      search.split('=')[0].includes('offer')
    if (prevAuth === undefined && user.auth === null && !shouldOpenModal) {
      setOpenModal(true)
    }
  }, [prevAuth, user.auth, hash, search])

  function afterChange(index) {}

  return (
    <OnboardingModal
      open={openModal}
      ctaText={translate('watchNow')}
      afterChange={afterChange}
      slides={[
        { title: translate('welcomeMsg1'), imageSrc: translate('welcomeIMG1') },
        { title: translate('welcomeMsg2'), imageSrc: translate('welcomeIMG2') },
        { title: translate('welcomeMsg3'), imageSrc: translate('welcomeIMG3') },
      ]}
      onClose={() => setOpenModal(false)}
      logo={Logo}
    />
  )
}

export default withLanguage(withContext(Onboarding))
