import util from 'util'

/**
 * @param { success, errors }
 * * Wether to log api requests or not
 */
const logApiRequests = {
  success: true,
  errors: true,
  info: true,
}

/**
 * * Log utility function
 * @param logData: object or string to log
 * @param status: color of the log
 */
export const log = (
  logData: Object | string,
  status?: 'success' | 'error' | 'info'
) => {
  let parsedObject

  if (typeof logData === 'object') {
    parsedObject = util.inspect(logData, {
      showHidden: false,
      depth: null,
    })
  }

  if (typeof logData === 'string') {
    parsedObject = logData
  }

  switch (status) {
    case 'success':
      logApiRequests.success &&
        console.log('\x1b[32m%s\x1b[0m', '✅ ' + parsedObject)
      break
    case 'error':
      logApiRequests.errors &&
        console.log('\x1b[31m%s\x1b[0m', '❌ ' + parsedObject)
      break
    case 'info':
      logApiRequests.info &&
        console.log('\x1b[36m%s\x1b[0m', '💫 ' + parsedObject)
      break

    default:
      console.log(parsedObject)
      break
  }
}

/**
 * * Api Init logs
 */
log('')
log(
  `Success logs are ${logApiRequests.success ? 'enabled' : 'disabled'}`,
  'success'
)
log(
  `Errors logs are ${logApiRequests.errors ? 'enabled' : 'disabled'}`,
  'error'
)
log(`Info logs are ${logApiRequests.errors ? 'enabled' : 'disabled'}`, 'info')
log('')
