import React from 'react'
import { withLanguage } from '../../providers/LanguageContext'
import { withContext } from '../../providers/UserContext'
import './style.scss'

function ReadMore(props) {
  const { text, translate } = props
  const showMore = text.length > 200
  const [isLong, setIsLong] = React.useState(true)
  const helperText = isLong ? translate('aboutMore') : translate('aboutLess')
  const parsedText = text?.slice(
    0,
    isLong ? text.lastIndexOf(' ', 200) : text.length
  )
  return (
    <div className="read-more">
      {isLong ? `${parsedText}... ` : `${parsedText} `}
      {showMore && (
        <span onClick={() => setIsLong(!isLong)} className="rm-toggle">
          {helperText}
        </span>
      )}
    </div>
  )
}

export default withLanguage(withContext(ReadMore))
