import Env from "../../src/.env.js";
import { BrandId } from "../models/enums/BrandId.ts";

export const getBrandId = () => {
  switch (Env.env) {
    case "selego":
      return BrandId.Selego;
    case "myst":
      return BrandId.Myst;
    case "glitch":
      return BrandId.Glitch;
    default:
      return BrandId.Myst;
  }
};
