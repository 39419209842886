import {
  auth,
  firestore,
  persistence,
  firebaseInstance,
} from "./FirebaseService";

import axios from "axios";

import Env from "./../.env.js";
import { log } from "../utils/functions";

const plansMock = {
  prices: {
    data: [
      {
        productID: "price_1J7cDBIElqLJlzgQVvmSl05k",
        en: "US$1.96 / monthly",
        subtitleES:
          "+ Un cobro único de US$23.49, equivalente a US$1.96 mensuales + 7 días de prueba gratis + Cancela cuando quieras",
        subtitleEN:
          "+ One single payment of US$23.49, equivalent to US$1.96 per month + 7 days free trial + Cancel anytime",
        es: "US$1.96 / mensual",
        id: "price_1J7cDBIElqLJlzgQVvmSl05k",
        object: "price",
        active: true,
        billing_scheme: "per_unit",
        created: 1624954949,
        currency: "usd",
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: "prod_JXXY9ANkbPCl9h",
        recurring: {
          aggregate_usage: null,
          interval: "year",
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: "licensed",
        },
        tax_behavior: "unspecified",
        tiers_mode: null,
        transform_quantity: null,
        type: "recurring",
        unit_amount: 2349,
        unit_amount_decimal: "2349",
      },
      {
        productID: "price_1J7cAWIElqLJlzgQLTWGjHwK",
        en: "US$3.99 / monthly",
        subtitleES: "+ 7 días de prueba gratis + Cancela cuando quieras",
        subtitleEN: "+ 7 days free trial + Cancel anytime",
        es: "US$3.99 / mensual",
        id: "price_1J7cAWIElqLJlzgQLTWGjHwK",
        object: "price",
        active: true,
        billing_scheme: "per_unit",
        created: 1624954784,
        currency: "usd",
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: "prod_JXXWP2jir3t5qg",
        recurring: {
          aggregate_usage: null,
          interval: "month",
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: "licensed",
        },
        tax_behavior: "unspecified",
        tiers_mode: null,
        transform_quantity: null,
        type: "recurring",
        unit_amount: 399,
        unit_amount_decimal: "399",
      },
    ],
  },
};

const UserServices = {
  getClientLocation: async () => {
    try {
      const request = await fetch(
        "https://api.ipapi.com/api/check?access_key=168ce034987bb53eb8ef5b77c8e086f7"
      );

      const regionsAvailable = await firestore
        .collection("GV")
        .doc("regions")
        .get();

      const regions = await regionsAvailable.data().default;

      const location = await request.json();

      const isServiceAvailable = regions.includes(location.country_code);

      return {
        ...location,
        country_code: isServiceAvailable ? location.country_code : "NL",
      };
    } catch (e) {
      log("Error @IPAPI", "errror");
      log(e, "error");

      return {
        ip: "127.0.0.1",
        country_code: "XY",
      };
    }
  },
  saveStripeId: async (sessionId, interval) => {
    try {
      const user = auth.currentUser;

      const {
        data: { subscription_id },
      } = await axios.post(
        Env.cloudfunctions_url + "stripeSessionIdToSubscriptionId",
        {
          session_id: sessionId,
        }
      );

      const { data } = await axios.put(Env.api_url + "/users", {
        uid: user.uid,
        stripe_id: subscription_id,
        active: true,
        subscription_status: "trial",
        subscription_period: interval === "month" ? "monthly" : "annually",
      });

      return data;
    } catch (error) {
      console.log("\x1b[31m%s\x1b[0m", `❌ Error @saveStripeId: ${error}`);
    }
  },
  createUser: (email, password, language) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { user } = await auth.createUserWithEmailAndPassword(
          email,
          password
        );

        fetch(Env.cloudfunctions_url + "addMailchimpMember", {
          method: "POST",
          body: JSON.stringify({ email, language }),
        });

        axios.post(Env.api_url + "/users", {
          uid: user.uid,
          email,
          language: "en",
          active: false,
          subscription_status: "off",
          subscription_platform: "stripe",
          subscription_period: "",
          apple_id: "",
          google_id: "",
          stripe_id: "",
        });

        resolve();
      } catch (error) {
        reject(error.message);
      }
    });
  },
  getUserData: (uid) => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios({
          method: "GET",
          url: `${Env.api_url}/users/${uid}`,
        })
          .then(({ data }) => {
            const {
              active,
              language,
              subscription_period,
              subscription_platform,
              stripe_id,
            } = data;

            resolve({
              subscription_type: subscription_period.toUpperCase(),
              subscribed_at:
                subscription_platform === "apple"
                  ? "ios"
                  : subscription_platform === "google"
                  ? "android"
                  : "stripe",
              subscribed: active,
              default_language: language,
              stripe_id,
            });
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } catch (error) {
        reject(error.message);
      }
    });
  },
  getPlans: (region) => {
    return new Promise(async (resolve, reject) => {
      resolve(plansMock.prices.data);
      // fetch(Env.cloudfunctions_url + "getPlans", {
      //   method: "POST",
      //   body: JSON.stringify({ region }),
      // })
      //   .then((response) => response.json())
      //   .then(async (data) => {
      //     console.log(data.prices.data);
      //     resolve(data.prices.data);
      //   })
      //   .catch((error) => {
      //     reject(error);
      //   });
    });
  },
  login: (email, password) => {
    return new Promise((resolve, reject) => {
      auth
        .setPersistence(persistence.local)
        .then(() => {
          auth
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout: () => {
    return new Promise((resolve, reject) => {
      auth
        .signOut()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateUser: (email, password) => {
    return new Promise((resolve, reject) => {
      var user = auth.currentUser;
      const promises = [];
      if (password.length > 0) {
        promises.push(user.updatePassword(password));
      }
      if (user.email !== email) {
        promises.push(user.updateEmail(email));
      }
      Promise.all(promises)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPassword: (email) => {
    return new Promise((resolve, reject) => {
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateUserData: (data, uid) => {
    return new Promise(async (resolve, reject) => {
      const user = auth.currentUser;
      if (user && uid) {
        try {
          const { data: res } = await axios.put(Env.api_url + "/users", {
            uid,
            ...data,
          });

          resolve(res);
        } catch (error) {
          reject(error.message);
        }
      }
    });
  },
  subscribe: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const user = auth.currentUser;

        const { data } = await axios.put(Env.api_url + "/users", {
          uid: user.uid,
          active: true,
          subscription_status: "trial",
          renew: true,
        });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  cancelPlan: (stripe_id, cancelFormData) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!stripe_id) {
          return resolve({ message: "No stripe id" });
        }

        if (stripe_id) {
          fetch(Env.cloudfunctions_url + "cancelSubscription", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              stripe_id,
              cancelFormData: cancelFormData || "",
            }),
          })
            .then((response) => response.json())
            .then(async (res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        }
      } catch (error) {
        reject(error.message);
      }
    });
  },
  log: (file, data) => {
    return new Promise(async (resolve, reject) => {
      const currentTime =
        firebaseInstance.firestore.FieldValue.serverTimestamp();
      const user = auth.currentUser;
      const docRefCollection = firestore
        .collection("user_log")
        .where("uid", "==", user.uid)
        .where("file", "==", file)
        .limit(1);
      docRefCollection.get().then((querySnapshot) => {
        if (querySnapshot.empty) {
          firestore.collection("user_log").add({
            uid: user.uid,
            file: file,
            data: data,
            created_at: currentTime,
            updated_at: currentTime,
          });
        } else {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
              uid: user.uid,
              file: file,
              data: data,
              created_at: currentTime,
              updated_at: currentTime,
            });
          });
        }
      });
      resolve();
    });
  },
  getLog: (file) => {
    return new Promise(async (resolve, reject) => {
      const user = auth.currentUser;
      const docRefCollection = firestore
        .collection("user_log")
        .where("uid", "==", user.uid)
        .where("file", "==", file)
        .limit(1);
      docRefCollection.get().then((querySnapshot) => {
        if (querySnapshot.empty) {
          resolve(null);
        } else {
          querySnapshot.forEach((doc) => {
            resolve(doc.data());
          });
        }
      });
    });
  },
};

export default UserServices;
