import React from "react";
import "./styles.scss";
import Topbar from "../../components/Topbar/index";
import MainItem from "../../components/MainItem/index";
import Cast from "../../components/Cast/index";
import Season from "../../components/Season/index";
import Author from "../../components/Author/index";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import SerieServices from "../../services/SerieServices";
import { withLanguage } from "../../providers/LanguageContext";
import trackEvent, { FIREBASE_EVENTS } from "../../utils/trackEvent";
import { withContext } from "../../providers/UserContext";
import Env from "./../../.env.js";
import { logScreenView } from "../../utils/analyticsEvents";

class DetailSerie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainItem: null,
    };
  }

  async componentDidMount() {
    try {
      const serieResponse = await SerieServices.getSerie(
        this.props.match.params.id
      );
      const serie = serieResponse.data;

      logScreenView({
        name: `${serie.contentType} - ${serie.contentId}/${serie.titleEN} - Content View`,
      });

      console.log("serie", serie);

      this.setState({
        mainItem: {
          image: serie.imageDHigh,
          imageMobile: serie.imageMHigh,
          imageDetailsMobile: serie.imageMDetails,
          categoryES: serie.tagES,
          categoryEN: serie.tagEN,
          titleEN: serie.titleEN,
          titleES: serie.titleES,
          contentType: serie.contentType,
          author: [serie.director1, serie.director2, serie.director3],
          year: serie.year,
          resumeEN: serie.descriptionEN,
          resumeES: serie.descriptionES,
          minutes: serie.minutes,
          audio: serie.audio?.join(", "),
          cc: serie.CC,
          seasons: serie.seasons,
          id: this.props.match.params.id,
          editorialEN: serie.editorialEN,
          editorialES: serie.editorialES,
        },
      });
    } catch (err) {
      console.log(err);
      this.props.history.push("/404");
    }
  }

  render() {
    console.log("this.state.mainItem", this.state);
    const { mainItem } = this.state;
    if (mainItem) {
      const { seasons, cast } = mainItem;
      return (
        <div className="container-list-movies">
          <Topbar />
          {mainItem !== null && (
            <>
              <MainItem item={mainItem} type="detail-serie" />
              <Container fluid="lg" className="no-padding-responsive">
                <Row>
                  <Col className="author-col">
                    <Author editorial={mainItem} />
                  </Col>
                </Row>

                {Array.isArray(seasons) && (
                  <Row className="cast-section">
                    <Col className="cast-col">
                      <Season
                        seasons={this.state.mainItem.seasons}
                        serieId={this.props.match.params.id}
                      />
                    </Col>
                  </Row>
                )}
                {/* {Env.env !== "glitch" && cast && (
                  <Row className="cast-section">
                    <Col className="cast-col">
                      <Cast
                        title={this.props.translate("castAndCrew")}
                        cast={cast}
                      />
                    </Col>
                  </Row>
                )} */}
              </Container>
            </>
          )}
        </div>
      );
    }
    return <Spinner />;
  }
}

export default withLanguage(withContext(DetailSerie));
