import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { loadStripe } from "@stripe/stripe-js";
import Env from "./../../.env.js";

function PlansBox(props) {
  const { labels, plans, color, onClickLogin, apiKey } = props;

  const stripePromise = loadStripe(apiKey);

  async function handleSumit(plan) {
    const myInit = {
      method: "POST",
      body: JSON.stringify({
        priceId: plan.id,
      }),
      headers: {
        "Content-Type": "text/plain",
      },
    };

    const stripe = await stripePromise;
    const response = await fetch(
      Env.cloudfunctions_url + "createCheckoutSession",
      myInit
    );
    const session = await response.json();
    stripe.redirectToCheckout({
      sessionId: session.id,
    });
  }

  return (
    <div className="w-full flex items-center flex-col">
      <h2 className="text-2xl uppercase text-center text-white font-bold">
        {labels.subTitle}
      </h2>
      <div className="text-center text-white text-sm leading-7 my-2 font-bold">
        {labels.details}
      </div>
      <div className="w-full sm:w-2/3">
        <div className="flex flex-col w-full">
          {plans.map((plan, index) => (
            <>
              <div className="mt-6" key={`plan-${plan.id}`}>
                <Button
                  className="w-full px-2"
                  style={{ letterSpacing: 0 }}
                  onClick={() => handleSumit(plan)}
                  color={color}
                  label={plan.label}
                />
                {plan.discountLabel && (
                  <p className="text-white text-sm mt-1 text-center font-medium">
                    {plan.discountLabel}
                  </p>
                )}
              </div>
              {index < plans.length - 1 && (
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <svg
                    width="100"
                    height="1"
                    viewBox="0 0 100 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line y1="0.5" x2="100" y2="0.5" stroke="white" />
                  </svg>
                  <span
                    className="font-poppins font-bold"
                    style={{ color: "white" }}
                  >
                    {labels.or}
                  </span>
                  <svg
                    width="100"
                    height="1"
                    viewBox="0 0 100 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line y1="0.5" x2="100" y2="0.5" stroke="white" />
                  </svg>
                </div>
              )}
            </>
          ))}
        </div>
        <div className="mx-auto w-full  mt-10 text-center">
          <span className="text-white leading-snug text-xs">
            {labels.termsAndConditions}
          </span>
        </div>
        {onClickLogin && (
          <button
            type="button"
            onClick={onClickLogin}
            className="mx-auto block mt-10 text-white text-center text-base self-center"
          >
            {labels.alreadyMember}{" "}
            <span className="underline font-bold">{labels.login}</span>
          </button>
        )}
      </div>
    </div>
  );
}

PlansBox.propTypes = {
  apiKey: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary"]),
  onClickLogin: PropTypes.func,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      hasCoupon: PropTypes.bool,
      label: PropTypes.string,
      discountLabel: PropTypes.string,
    })
  ),
  onChangePlan: PropTypes.func,
  labels: PropTypes.shape({
    subTitle: PropTypes.string,
    details: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.object,
    ]),
    login: PropTypes.string,
    alreadyMember: PropTypes.string,
    or: PropTypes.string,
  }),
};
export default PlansBox;
