import { useEffect, useState } from 'react'
import { firestore } from '../../services/FirebaseService'
import { withContext } from '../../providers/UserContext'
import { withLanguage } from '../../providers/LanguageContext'
import { useHistory } from 'react-router-dom'
import Env from '../../.env.js'

function CancelSubStep2({ translate, currentLanguage, onSubmit }) {
  const [trendingIds, setTrendingIds] = useState()
  const [trendingContents, setTrendingContents] = useState()
  const history = useHistory()

  useEffect(() => {
    firestore
      .collection('trending')
      .limit(3)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) =>
          setTrendingIds((prevData) =>
            prevData ? [...prevData, doc.data()] : [doc.data()]
          )
        )
      })
  }, [])

  useEffect(() => {
    if (trendingIds)
      trendingIds.map((id) =>
        firestore
          .doc(`${id.category}/${id.id}`)
          .get()
          .then(
            (doc) =>
              doc.exists &&
              setTrendingContents((preContents) =>
                preContents
                  ? preContents.find((pCont) => pCont.id === doc.id)
                    ? preContents
                    : [...preContents, { id: doc.id, ...doc.data() }]
                  : [{ id: doc.id, ...doc.data() }]
              )
          )
      )
  }, [trendingIds])

  const renderContent = (content) => (
    <div className="lg:w-3/12">
      <h5 className="primary-color font-bold text-lg uppercase leading-6">
        {content[`title${currentLanguage.toUpperCase()}`]}
      </h5>
      <div
        className="rounded-tr-3xl h-48 my-4"
        style={{
          backgroundImage: `url("${content.imageDPeque}")`,
          backgroundPosition: 'center',
        }}
      />
      <p className="text-white text-xs">
        {content[`description${currentLanguage.toUpperCase()}`].substring(
          0,
          125
        )}
        ...
      </p>
    </div>
  )

  return (
    <div className="lg:mr-24">
      <h1 className="text-white font-bold my-4">{translate('wait')}</h1>
      <p className="text-white">{translate('cancelSubStep2Msg')}</p>
      <div className="lg:flex w-full justify-between">
        {trendingContents?.map(renderContent)}
      </div>
      <div className="my-4 text-left">
        <button
          onClick={() => onSubmit()}
          className="uppercase text-white border px-3 py-3 mb-2"
        >
          {translate('thanksNo')}
        </button>
        <button
          onClick={() => history.push('/')}
          className={`uppercase ${
            Env.env === 'glitch' ? '' : 'text-white'
          } primary-background-color px-3 py-3 lg:mx-5`}
        >
          {translate('ok')}
        </button>
      </div>
    </div>
  )
}

export default withContext(withLanguage(CancelSubStep2))
