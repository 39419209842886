import React from "react";
import "./styles.scss";
import Topbar from "../../components/Topbar/index";
import MainItem from "../../components/MainItem/index";
import Cast from "../../components/Cast/index";
import Author from "../../components/Author/index";
import { Row, Col, Container } from "react-bootstrap";
import MovieServices from "../../services/MovieServices";
import { withLanguage } from "../../providers/LanguageContext";
import trackEvent, { FIREBASE_EVENTS } from "../../utils/trackEvent";
import Env from "./../../.env.js";
import { withContext } from "../../providers/UserContext";
import { logScreenView } from "../../utils/analyticsEvents";

class DetailMovie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainItem: null,
    };
  }

  async componentDidMount() {
    try {
      const movieResponse = await MovieServices.getMovie(
        this.props.match.params.id
      );

      const movie = movieResponse.data;
      console.log("movie", movie);

      logScreenView({
        name: `${movie.contentType} - ${movie.contentId}/${movie.titleEN} - Content View`,
      });

      this.setState({
        mainItem: {
          image: movie.imageDHigh,
          imageMobile: movie.imageMHigh,
          imageDetailsMobile: movie.imageMDetails,
          categoryES: movie.tagES,
          categoryEN: movie.tagEN,
          titleEN: movie.titleEN,
          titleES: movie.titleES,
          author: [movie.director1, movie.director2, movie.director3],
          year: movie.year,
          resumeEN: movie.descriptionEN,
          resumeES: movie.descriptionES,
          contentType: movie.contentType,
          minutes: movie.minutes,
          audio: movie.audio?.join(", "),
          cc: movie.CC,
          editorialEN: movie.editorialEN,
          editorialES: movie.editorialES,
          id: this.props.match.params.id,
        },
      });
    } catch (err) {
      this.props.history.push("/404");
    }
  }

  render() {
    return (
      <div className="container-list-movies">
        <Topbar />

        {this.state.mainItem !== null && (
          <>
            <MainItem item={this.state.mainItem} type="detail-movie" />
            <Container fluid="lg" className="no-padding-responsive">
              {/* {Env.env !== "glitch" && (
                <Row className="cast-section">
                  <Col className="cast-col">
                    <Cast
                      title={this.props.translate("castAndCrew")}
                      cast={this.state.mainItem.cast}
                    />
                  </Col>
                </Row>
              )} */}
              <Row>
                <Col className="author-col">
                  <Author editorial={this.state.mainItem} />
                </Col>
              </Row>
            </Container>
          </>
        )}
      </div>
    );
  }
}

export default withLanguage(withContext(DetailMovie));
