import React from "react";
import { useHistory } from "react-router-dom";
import { BasicModal } from "../tribes-media-ui/components";
import { withLanguage } from "../providers/LanguageContext";
import { useModals } from "../providers/ModalsContext";
import { withContext } from "../providers/UserContext";
import { Logo } from "./../resources/Logos";

function SlotsFull(props) {
  const { translate } = props;
  const history = useHistory();
  const { openSlotsFull, onToggle } = useModals();

  function onClose() {
    if (history.location?.hash === "#openSlotsFull") {
      history.replace(history.location.pathname);
    }
    onToggle("openSlotsFull");
  }

  return (
    <BasicModal
      title={translate("tooManyUsers")}
      content={translate("tooManyUsersContent")}
      onClose={onClose}
      logo={Logo}
      open={openSlotsFull}
    />
  );
}

export default withLanguage(withContext(SlotsFull));
