import React from "react";
import { Route, Redirect } from "react-router-dom";
import { withContext } from "../providers/UserContext";
import { withLanguage } from "../providers/LanguageContext";

const ProtectedPaymentRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.user.auth && rest.user.plan) {
          return <Component {...rest} {...props} />;
        }
        const regex = /\/player\/((movie|serie)\/([\w\d.-]+)(\/|$))/;
        const match = props.location.pathname.match(regex);
        const baseUrl = match?.[1];
        if (!rest.user.auth) {
          console.log(`/${baseUrl}#openLogin`);
          return <Redirect to={`/${baseUrl}#openLogin`} />;
        } else {
          return (
            <Redirect
              to={`/${baseUrl}#${
                rest.user.plan ? "openEditSubscription" : "openSubscription"
              }`}
            />
          );
        }
      }}
    />
  );
};

export default withLanguage(withContext(ProtectedPaymentRoute));

