import { firestore } from "./FirebaseService";

const LanguageServices = {
  getData: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const dataRef = firestore.collection("GV");
        const snapshot = await dataRef.get();
        var result = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          result[doc.id] = data;
        });
        resolve(result);
      } catch (error) {
        reject(error.message);
      }
    });
  },
  getLanguagesData: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const dataRef = firestore.collection("languages");
        const snapshot = await dataRef.get();
        const result = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          result.push(data);
        });
        resolve(result);
      } catch (error) {
        reject(error.message);
      }
    });
  },
};

export default LanguageServices;

