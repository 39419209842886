import Modal from "../Modal";
import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.css";

function OfferModal(props) {
  const { open, onClose, onClick, labels, buttonClassName } = props;
  const [loading, setLoading] = useState(true);
  const image = new Image();
  image.addEventListener("load", () => setLoading(false));
  image.src = labels.imgSvg;
  if (loading) return <React.Fragment />;
  return (
    <Modal open={open} onClose={onClose} className="offer-modal">
      <img alt="logo" className="my-2 label-img" src={labels.imgSvg} />
      <div className="flex items-center flex-col">
        <div className="flex items-center my-4 flex-col">
          <div className="flex w-full items-center flex-wrap justify-center mb-2">
            <span className="text-white">{labels.bodyPart1}</span>
            <button
              className={`uppercase mx-2 font-bold ${buttonClassName}`}
              onClick={onClick}
            >
              {labels.button}
            </button>
            <span className="text-white">{labels.bodyPart2}</span>
          </div>
          <div className="text-white text-center leading-9">
            {labels.secondLine}
          </div>
        </div>
        <div className="text-white text-center my-4 px-5">
          {labels.subtitle}
        </div>
      </div>
    </Modal>
  );
}

OfferModal.prototype = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  buttonClassName: PropTypes.string,
  labels: PropTypes.shape({
    title: PropTypes.string,
    bodyPart1: PropTypes.string,
    bodyPart2: PropTypes.string,
    promo: PropTypes.string,
    secondLine: PropTypes.string,
    button: PropTypes.string,
    subtitle: PropTypes.string,
    imgSvg: PropTypes.string,
  }),
};

export default OfferModal;
