import React, { Component, createContext } from 'react'
import { auth } from '../services/FirebaseService'
import UserServices from '../services/UserServices'
import LanguageServices from '../services/LanguageServices'
import { UserProvider } from './UserContext'

export const UserContext = createContext({
  user: null,
  currentLanguage: 'en',
  translate: null,
})

class AuthProvider extends Component {
  state = {
    user: {
      auth: undefined,
      data: null,
      plan: undefined,
    },
    currentLanguage: 'en',
    data: null,
  }

  componentDidMount = async () => {
    try {
      const [lanData] = await Promise.all([LanguageServices.getData()])
      auth.onAuthStateChanged((userAuth) => {
        let currentUser = this.state.user
        currentUser.auth = userAuth
        this.setState({ user: currentUser }, () => {
          this.loadUserData()
        })
      })
      this.setState({
        data: lanData,
      })
    } catch (e) {
      console.log(e)
    }
  }

  loadUserData = async () => {
    if (this.state.user.auth !== null) {
      try {
        const res = await UserServices.getUserData(this.state.user.auth.uid)
        let currentUser = this.state.user
        currentUser.data = res
        currentUser.plan = res.subscribed || res.trial || false
        currentUser.stripeId = res.stripe_id || ''
        this.setState({
          user: currentUser,
          currentLanguage: res.default_language || 'en',
        })
      } catch (e) {
        let currentUser = this.state.user
        currentUser.plan = null
        this.setState({
          user: currentUser,
        })
      }
    } else {
      this.setState({
        user: {
          auth: null,
          data: null,
          plan: null,
        },
        currentLanguage: 'en',
      })
    }
  }

  translate = (index) => {
    if (this.state.data !== null) {
      if (
        this.state.currentLanguage.toLowerCase() === 'en' ||
        this.state.currentLanguage === null
      ) {
        return this.state.data[index]?.en
      }
      return this.state.data[index]?.es
    }
  }

  switchLanguage = (code) => {
    this.state.user.data &&
      UserServices.updateUserData({ default_language: code })
    this.setState((state) => ({
      currentLanguage: code,
      user: {
        ...state.user,
        data: { ...state.user.data, default_language: code },
      },
    }))
  }

  render() {
    return (
      <UserProvider
        value={{
          user: this.state.user,
          loadUserData: this.loadUserData,
          currentLanguage: this.state.currentLanguage,
          translate: this.translate,
          switchLanguage: this.switchLanguage,
        }}
      >
        {this.state.user.plan !== undefined && this.props.children}
      </UserProvider>
    )
  }
}

export default AuthProvider
