import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { StripeProvider } from 'react-stripe-elements'
import Modal from '../Modal'
import './styles.css'
import PlansBox from '../PlansBox'
import { useLocation } from 'react-router-dom'

function SubscribeModal(props) {
  const {
    region,
    open,
    onClose,
    logo,
    color,
    plans,
    apiKey,
    onClickLogin,
    labels,
    onSubmit,
    onProcessStarted,
  } = props
  const [planSelected, setPlanSelected] = React.useState(null)
  const { search } = useLocation()

  useEffect(() => {
    search.split('=')[0].includes('offer') &&
      setPlanSelected(plans?.find((plan) => plan.hasCoupon)?.id)
  }, [plans, search])

  function _onClose() {
    onClose()
    setTimeout(() => {
      setPlanSelected(null)
    }, 300)
  }

  function onChangePlan(planId) {
    setPlanSelected(planId)
    onProcessStarted(planId)
  }

  useEffect(() => {
    planSelected && onSubmit(planSelected)
  }, [planSelected, onSubmit])

  return (
    <Modal
      open={open}
      id="login-modal"
      onClose={_onClose}
      className="login-modal pt-10 font-poppins"
    >
      <StripeProvider apiKey={apiKey}>
        <>
          <div className="hidden lg:block" style={{ width: '35%' }}>
            <img alt="logo" src={logo} />
          </div>
          {!planSelected && (
            <PlansBox
              apiKey={apiKey}
              onSubmit={onSubmit}
              region={region}
              color={color}
              onClickLogin={onClickLogin}
              plans={plans}
              onChangePlan={onChangePlan}
              labels={{
                subTitle: labels.subTitle,
                details: labels.details,
                termsAndConditions: labels.termsAndConditions,
                login: labels.login,
                alreadyMember: labels.alreadyMember,
                or: labels.or,
              }}
            />
          )}
        </>
      </StripeProvider>
    </Modal>
  )
}

SubscribeModal.propTypes = {
  open: PropTypes.bool,
  logo: PropTypes.element,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  apiKey: PropTypes.string,
  hasCoupon: PropTypes.bool,
  autoCoupon: PropTypes.string,
  onClickLogin: PropTypes.func,
  error: PropTypes.string,
  success: PropTypes.string,
  labels: PropTypes.shape({
    or: PropTypes.string,
    subTitle: PropTypes.string,
    details: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.object,
    ]),
    termsAndConditions: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    login: PropTypes.string,
    alreadyMember: PropTypes.string,
    cardNumber: PropTypes.string,
    cardExpiration: PropTypes.string,
    cardCVC: PropTypes.string,
    confirm: PropTypes.string,
    coupon: PropTypes.string,
  }),
  validateCoupon: PropTypes.func,
  onProcessStarted: PropTypes.func,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      hasCoupon: PropTypes.bool,
      label: PropTypes.string,
      discountLabel: PropTypes.string,
    })
  ),
  color: PropTypes.oneOf(['primary', 'secondary']),
}

SubscribeModal.defaultProps = {
  color: 'primary',
}

export default SubscribeModal
