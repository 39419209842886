import React, { useCallback, useEffect, useState } from 'react'
import { withContext } from '../providers/UserContext'
import FAQQuestion from './FAQQuestion'
import AppStore from '../resources/app-store.svg'
import GooglePlay from '../resources/google-play.svg'
import EN from '../resources/en.svg'
import ES from '../resources/es.svg'
import { Button } from 'react-bootstrap'
import { useModals } from './../providers/ModalsContext'
import Env from './../.env.js'

function FAQ(props) {
  const { translate, user, title, questions, ext } = props
  const [exts, setExts] = useState([])
  const { onToggle } = useModals()
  const onOpen = useCallback(() => {
    if (user.auth) {
      onToggle('openSubscription', true)
    } else {
      onToggle('openSignup')
    }
  }, [onToggle, user.auth])
  useEffect(() => {
    if (ext) {
      const extQuestions = []
      questions.map((question, index) => {
        switch (index) {
          case 1:
          case 4:
            extQuestions[index] = (
              <div className="d-flex my-4">
                <a
                  href={Env.appStore}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="App Store" src={AppStore} className="mr-3" />
                </a>
                <a
                  href={Env.googlePlay}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="Google Play" src={GooglePlay} className="ml-3" />
                </a>
              </div>
            )
            break
          case 2:
            extQuestions[index] = (
              <Button
                onClick={() => onOpen()}
                className={`primary-background-color border-0 rounded-0 text-uppercase py-2 ls-3 px-9 lg:px-12 my-4 ${
                  (Env.env === 'glitch' && 'black-color') ||
                  (Env.env === 'selego' && 'black-color')
                }`}
              >
                {translate('aboutUsBtn')}
              </Button>
            )
            break
          case 3:
            extQuestions[index] = (
              <div className="d-flex my-4">
                <img alt="EN" src={EN} className="mr-3" />
                <img alt="ES" src={ES} className="ml-3" />
              </div>
            )
            break
          case 5:
            extQuestions[index] = (
              <div className="d-flex flex-wrap align-items-center my-1">
                <span className="text-white mr-1">
                  {translate('contactUs')}
                </span>
                <svg
                  className="mx-1 primary-color"
                  width="20"
                  height="14"
                  viewBox="0 0 20 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M19.428 13.504H0.572C0.256 13.504 0 13.248 0 12.936V0.576C0 0.536 0.004 0.496 0.012 0.456C0.02 0.416 0.028 0.388 0.04 0.36C0.04 0.356 0.044 0.352 0.044 0.348C0.076 0.28 0.108 0.232 0.144 0.192C0.196 0.132 0.26 0.084 0.328 0.052C0.396 0.024 0.468 0.004 0.548 0C0.556 0 0.568 0 0.576 0H19.424C19.432 0 19.444 0 19.452 0C19.532 0.004 19.604 0.02 19.672 0.052C19.74 0.084 19.804 0.132 19.86 0.192C19.924 0.264 19.968 0.348 19.988 0.444C19.988 0.444 19.988 0.444 19.988 0.448C19.996 0.492 20 0.532 20 0.576V12.936C20 13.248 19.744 13.504 19.428 13.504ZM1.14 12.364H18.856V1.776L10.36 8.732C10.148 8.904 9.848 8.904 9.636 8.732L1.14 1.776V12.364ZM2.168 1.14L10 7.556L17.832 1.14H2.168Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="20" height="13.504" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <a
                  href={`mailto:${translate('tribesEmail').toLowerCase()}`}
                  className="text-white mx-1"
                >
                  {translate('tribesEmail')}
                </a>
              </div>
            )
            break
          default:
            extQuestions[index] = <React.Fragment />
        }
        return extQuestions
      })
      setExts(extQuestions)
    }
  }, [ext, onOpen, questions, translate])

  return (
    <section className="mb-8 lg:mb-16">
      <h3 className="fw-700 text-white fw-500 fs-17 ls-3 lg:text-center mb-5">
        {title}
      </h3>
      {questions.map((question, index) =>
        index % 2 ? (
          <React.Fragment />
        ) : (
          <div className="d-flex flex-wrap justify-between">
            <FAQQuestion id={question} ext={exts[index]} />
            <FAQQuestion id={questions[index + 1]} ext={exts[index + 1]} />
          </div>
        )
      )}
    </section>
  )
}

export default withContext(FAQ)
