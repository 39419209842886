import React from 'react'
import { useRef } from 'react'
import { toast } from 'react-toastify'
import { EditAccountModal, LoginModal } from '../tribes-media-ui/components'
import { withLanguage } from '../providers/LanguageContext'
import { useModals } from '../providers/ModalsContext'
import { withNotification } from '../providers/NotificationContext'
import { withContext } from '../providers/UserContext'
import { auth } from '../services/FirebaseService'
import UserServices from '../services/UserServices'
import { Logo } from './../resources/Logos'

function EditAccount(props) {
  const { translate, user, currentLanguage, runNotification } = props
  const { openEditAccount, onToggle } = useModals()
  const [loading, setLoading] = React.useState(false)
  const [openLogin, setOpenLogin] = React.useState(false)
  const values = useRef(null)

  function onSubmit(form) {
    values.current = form
    setOpenLogin(true)
  }

  async function onLogin(form) {
    setLoading(true)
    try {
      await UserServices.login(form.email, form.password)
      const isNewEmail = values.current.email !== user.auth.email
      if (isNewEmail) {
        await auth.currentUser.updateEmail(values.current.email)
        UserServices.updateUserData({ email: values.current.email }, user.uid)
      }
      if (values.current.password > 0) {
        await UserServices.login(
          isNewEmail ? values.current.email : form.email,
          form.password
        )
        await auth.currentUser.updatePassword(values.current.password)
      }
      setLoading(false)
      setOpenLogin(false)
      onToggle('openEditAccount')
      toast.success(translate('successMsg'))
    } catch (e) {
      console.log(e)
      setLoading(false)
      runNotification(e, 'error')
    }
  }

  function getSubscriptionLabel() {
    const label = `${translate('subscription')} ${translate(
      user.data.subscription_type?.toLowerCase()
    )}`
    return label.toUpperCase()
  }

  return (
    <>
      <EditAccountModal
        open={openEditAccount}
        onClose={() => onToggle('openEditAccount')}
        email={user?.auth?.email || ''}
        disabled={loading}
        labels={{
          title: translate('bannerTitle'),
          save: translate('saveBtn'),
          subscription: user.plan ? getSubscriptionLabel() : null,
          email: translate('email'),
          password: translate('password'),
        }}
        language={currentLanguage?.toLowerCase()}
        onSubmit={onSubmit}
        logo={Logo}
        color="secondary"
      />
      <LoginModal
        open={openLogin}
        onClose={() => setOpenLogin(false)}
        title={translate('bannerTitle')}
        loginLabel={translate('login')}
        language={currentLanguage.toLowerCase()}
        disabled={loading}
        onSubmit={onLogin}
        color="secondary"
        logo={Logo}
      />
    </>
  )
}

export default withNotification(withLanguage(withContext(EditAccount)))
