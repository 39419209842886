import React from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import Carousel, { consts } from "react-elastic-carousel";
import useWindowSize from "../../hooks/useWindowSize";
import Env from "../../.env.js";

const breakPoints = [
  { width: 420, itemsToShow: 2 },
  { width: 576, itemsToShow: 3 },
  { width: 768, itemsToShow: 4 },
  { width: 992, itemsToShow: 5 },
];

function Season(props) {
  const [width] = useWindowSize();
  const { seasons, serieId } = props;
  const [selectedSeason, setSelectedSeason] = React.useState(1);
  const isSelego = Env.env === "selego";

  console.log("serieId", serieId);

  function renderArrows({ type, onClick, isEdge }) {
    let pointer = "";
    if (type === consts.PREV) {
      pointer = (
        <svg
          width="1.5em"
          height="1.5em"
          viewBox="0 0 16 16"
          className="bi bi-chevron-left"
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
      );
    } else {
      pointer = (
        <svg
          width="1.5em"
          height="1.5em"
          viewBox="0 0 16 16"
          className="bi bi-chevron-right"
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      );
    }
    return (
      <button className="arrow-link" onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  }
  function renderCarouselSeason(season, episode) {
    const image = width < 990 ? episode.imageMobile : episode.image;
    return (
      <div className="season-box">
        <div className="image-container">
          <img src={image} alt={`Season ${episode.number}`} />
          <div className="play-button-container">
            {/* <div className="watched">	{ episode.watched && ('WATCHED') }</div> */}
            <Link
              onClick={() => console.log({ season, episode })}
              to={
                "/player/serie/" +
                serieId +
                "/season/" +
                season.number +
                "/episode/" +
                episode.number
              }
              className="play-button"
            >
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                className="bi bi-play-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
              </svg>
            </Link>
          </div>
        </div>
        <div className="season-details">
          <p className="season-index">
            E{episode.number > 9 ? episode.number : `0${episode.number}`}
          </p>
          <div className="season-name"> {episode.title}</div>
        </div>
      </div>
    );
  }
  return (
    <div className="season">
      <div className={`title ${isSelego && "text-selego"}`}>
        SEASON
        <div className="seasons">
          {seasons.length > 0 &&
            seasons.map((s, index) => (
              <button
                className={
                  s.number === selectedSeason
                    ? "number-season active"
                    : "number-season"
                }
                onClick={() => setSelectedSeason(s.number)}
              >
                {s.number}
              </button>
            ))}
        </div>
      </div>
      <div className="carousel-season">
        <div className="cs-overlay" />
        {seasons.map((s) => (
          <Carousel
            className={selectedSeason === s.number ? "" : "d-none"}
            breakPoints={breakPoints}
            pagination={false}
            renderArrow={renderArrows}
          >
            {s.episodes.map((e) => renderCarouselSeason(s, e))}
          </Carousel>
        ))}
      </div>
    </div>
  );
}

export default Season;
