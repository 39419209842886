import React from "react";
import "./styles.scss";
import Topbar from "../../components/Topbar/index";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withContext } from "../../providers/UserContext";
import { Logo } from "./../../resources/Logos";

class SignUpSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="container-sign-up">
        <Topbar />

        <div className="sign-up success">
          <Row className="logo">
            <Col>
              <img src={Logo} style={{ width: "50%" }} alt="Logo" />
            </Col>
          </Row>
          <Row className="text-1">
            <Col>
              <span>{this.props.translate("bannerTitle")}</span>
            </Col>
          </Row>
          <Row className="text-2">
            <Col>
              <span>{this.props.translate("welcomeMsg")}</span>
            </Col>
          </Row>
          <Row className="start-enjoying">
            <Col>
              <Link to="/movies">
                <button>{this.props.translate("welcomeBtn")}</button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withContext(SignUpSuccess);
