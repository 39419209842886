import React, { useEffect } from "react";
import FlowSubs from "../tribes-media-ui/components/FlowSubs";
import { withContext } from "../providers/UserContext";
import { withModalsContext } from "../providers/ModalsContext";
import { withNotification } from "../providers/NotificationContext";
import { withLanguage } from "../providers/LanguageContext";
import UserServices from "../services/UserServices";
import { useHistory } from "react-router-dom";
import { useModals } from "../providers/ModalsContext";
import { logScreenView, logSignUp } from "../utils/analyticsEvents";

function FlowSubscription(props) {
  const { translate, currentLanguage, region, runNotification } = props;

  function onSignup(values) {
    return new Promise(async (resolve, reject) => {
      try {
        await UserServices.createUser(
          values.email,
          values.password,
          currentLanguage
        );

        logSignUp({
          method: "email",
        });

        resolve();
      } catch (e) {
        console.log(e);
        runNotification(e, "error");
        reject();
      }
    });
  }
  const { openSignup, onToggle } = useModals();

  const [plans, setPlans] = React.useState();
  const history = useHistory();

  React.useEffect(() => {
    async function loadPlans() {
      try {
        const _plans = await UserServices.getPlans(region);
        setPlans(_plans);
      } catch (e) {
        console.log(e);
      }
    }
    loadPlans();
  }, [region]);

  useEffect(() => {
    if (openSignup && plans !== undefined && plans?.length === 0)
      history.push("/unavailable");
  }, [plans, openSignup]);

  useEffect(() => {
    if (openSignup) {
      logScreenView({
        name: "Begin Account Creation View",
      });
    }
  }, [openSignup]);

  //
  const plansList = plans?.map((plan) => ({
    ...plan,
    label: plan[currentLanguage?.toLowerCase()],
    discountLabel: plan[`subtitle${currentLanguage?.toUpperCase()}`],
    id: plan.id,
    hasCoupon: plan.recurring.interval === "year",
    interval: plan.recurring.interval,
  }));

  if (plans && plans.length) {
    return (
      <FlowSubs
        onSubmit={onSignup}
        plans={plansList}
        open={openSignup}
        labels={{
          tittle1: translate("startSubscriptionPart1"),
          tittle2: translate("startSubscriptionPart2"),
          or: translate("or"),
          enjoSevenDays: translate("enjoSevenDays"),
          cancelAnyTime: translate("cancelAnyTime"),
          step: translate("step"),
          of: translate("of"),
          createAccount: translate("createAccount"),
          email: translate("email"),
          password: translate("password"),
          repeatPassword: translate("passwordFlow"),
          confirm: translate("confirmBtn"),
          termsAndConditions: translate("termsAndUse"),
          privacyPolicy: translate("privacyPolicy"),
          tribesMediaBV: translate("tribesMediaBV"),
          choosePlan: translate("choosePlan"),
          close: translate("close"),
          bestOffer: translate("bestOffer"),
          saveOneYear1: translate("saveOneYearPart1"),
          saveOneYear2: translate("saveOneYearPart2"),
          join: translate("pricingJoin"),
        }}
        onClose={() => {
          onToggle("openSignup");
        }}
        colorFlow="color-glitch"
        tycFlow="tyc-glitch"
        btnFlow="btn-confirm-glitch"
        bestFlow="best-offer-glitch"
        bColor="b-glitch"
      />
    );
  }
  return <React.Fragment />;
}

export default withNotification(
  withModalsContext(withLanguage(withContext(FlowSubscription)))
);
