import * as React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import RModal from 'react-modal'
import { motion } from 'framer-motion'
import usePrevious from '../../hooks/usePrevious'
import { bodyAnimation } from './utils'
import './styles.css'

function Modal(props) {
  const {
    open,
    id,
    padded,
    children,
    size,
    onClose,
    allowClose,
    className,
    parentClassName,
    labels,
  } = props
  const prevOpen = usePrevious(open)
  const [show, setShow] = React.useState(open)
  React.useEffect(() => {
    if (open && !show) setShow(true)
  }, [open, show])

  React.useEffect(() => {
    if (prevOpen && !open) {
      hide()
    }
  }, [open, prevOpen])

  function hide() {
    setTimeout(() => {
      setShow(false)
    }, 400)
  }
  return (
    <RModal
      isOpen={show}
      testId={id}
      ariaHideApp={false}
      onRequestClose={onClose}
      shouldCloseOnEsc={allowClose}
      shouldCloseOnOverlayClick={allowClose}
      overlayClassName={`${
        parentClassName ? 'bm-blur-fow' : ''
      } bm-blur inset-0 flex fixed`}
      className={`${
        parentClassName ? 'outline-none bm-body-flow' : 'bm-body-container'
      } flex flex-col m-auto`}
    >
      <>
        <div className={parentClassName ? 'top-bar' : ''}></div>
        <motion.div
          className={cx(
            className,
            padded && 'p-8',
            { 'bm-small': size === 'small', 'bm-medium': size === 'medium' },
            `${
              parentClassName ? 'bm-max ' : ''
            } max-w-full max-h-full font-poppins relative flex flex-col h-auto m-auto bg-white shadow-md overflow-y-auto`
          )}
          variants={bodyAnimation}
          transition={{ duration: 0.3 }}
          initial="close"
          animate={open ? 'open' : 'close'}
        >
          {allowClose && (
            <>
              <div
                onClick={onClose}
                className={`${
                  parentClassName ? 'bm-close-max' : 'bm-close'
                } z-10 cursor-pointer`}
              >
                {parentClassName && (
                  <p className="close-right">{labels.close}</p>
                )}
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${parentClassName ? 'hidden' : 'close-x'}`}
                >
                  <path
                    d="M11 1L1 11"
                    stroke="#5B595F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 1L11 11"
                    stroke="#5B595F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </>
          )}
          {children}
        </motion.div>
      </>
    </RModal>
  )
}

Modal.defaultProps = {
  size: 'medium',
  padded: true,
  allowClose: true,
}

Modal.propTypes = {
  open: PropTypes.bool,
  padded: PropTypes.bool,
  onClose: PropTypes.func,
  allowClose: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  size: PropTypes.oneOf(['small', 'medium']),
  id: PropTypes.string,
}

export default Modal
