import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { LoginModal } from '../tribes-media-ui/components'
import { withLanguage } from '../providers/LanguageContext'
import { useModals } from '../providers/ModalsContext'
import { withNotification } from '../providers/NotificationContext'
import { withContext } from '../providers/UserContext'
import UserServices from '../services/UserServices'
import trackEvent, { FIREBASE_EVENTS } from '../utils/trackEvent'
import { Logo } from './../resources/Logos'
import { logLogin, logScreenView } from '../utils/analyticsEvents'

function Login(props) {
  const { translate, currentLanguage, runNotification, onClickForgot } = props
  const [loading, setLoading] = React.useState(false)
  const history = useHistory()
  const { openLogin, onToggle } = useModals()

  async function onLogin(values) {
    setLoading(true)
    try {
      await UserServices.login(values.email, values.password)

      logLogin({
        method: 'email',
      })

      setLoading(false)
      onClose()
    } catch (e) {
      logScreenView({
        name: `Login Fail - ${values.email}`,
      })

      console.log(e)
      setLoading(false)
      runNotification(e, 'error')
    }
  }

  function onClose() {
    if (history.location?.hash === '#openLogin') {
      history.replace(history.location.pathname)
    }
    onToggle('openLogin')
  }

  function onClickSignup() {
    onToggle('openSignup')
  }

  useEffect(() => {
    if (openLogin) {
      logScreenView({
        name: 'Login Screen View',
      })
    }
  }, [openLogin])

  return (
    <LoginModal
      open={openLogin}
      onClose={onClose}
      title={translate('bannerTitle')}
      loginLabel={translate('login')}
      language={currentLanguage?.toLowerCase()}
      forgotLabel={translate('forgotPassword')}
      signupLabel={translate('tryForFree')}
      onClickSignup={onClickSignup}
      onClickForgot={onClickForgot}
      disabled={loading}
      onSubmit={onLogin}
      color="secondary"
      logo={Logo}
    />
  )
}

export default withNotification(withLanguage(withContext(Login)))
