import React from "react";
import ReactDOM from "react-dom";
import Env from "./.env.js";
import "./index.css";
import "./tribes-media-ui/css/tribes-media-ui.css";
import { applyTheme } from "./tribes-media-ui/theme/utils";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import TagManager from "react-gtm-module";

const { colors, googleTagManager } = Env;

applyTheme({
  primary: colors.alternative,
  secondary: colors.black || "white",
  primaryText: colors.black || "white",
  secondaryText: colors.alternative,
});

const tagManagerArgs = {
  gtmId: googleTagManager,
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
