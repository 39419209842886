import React, { useState, useEffect } from 'react'

import { Helmet as ReactHelmet } from 'react-helmet'

import { firestore } from '../../services/FirebaseService'
import { withLanguage } from '../../providers/LanguageContext'
import { withContext } from '../../providers/UserContext'

type HelmetProps = {
  currentLanguage: 'es' | 'en'
}

type Translation = {
  es: string
  en: string
}

type Translations = {
  keywords: Translation
  h3: Translation
  title: Translation
  h2: Translation
  h1: Translation
  description: Translation
}

const Helmet: React.FC<HelmetProps> = ({ currentLanguage }): JSX.Element => {
  const [translations, setTranslations] = useState<Translations>({
    keywords: {
      es: '',
      en: '',
    },
    h3: {
      es: '',
      en: '',
    },
    title: {
      es: '',
      en: '',
    },
    h2: {
      es: '',
      en: '',
    },
    h1: {
      es: '',
      en: '',
    },
    description: {
      es: '',
      en: '',
    },
  })

  useEffect(() => {
    const getSeoTranslations = async () => {
      const translations = firestore.collection('GV').doc('seo')
      const data = await translations.get()

      // @ts-ignore
      setTranslations(data.data())
    }

    getSeoTranslations()
  }, [])

  const { title, description, h1, h3, h2, keywords } = translations

  return (
    <>
      <ReactHelmet defer={false}>
        <meta charSet="utf-8" />
        <title>{title[currentLanguage]}</title>
        <meta name="description" content={description[currentLanguage]} />
        <meta name="keywords" content={keywords[currentLanguage]} />
      </ReactHelmet>

      <div className="d-none">
        <h1>{h1[currentLanguage]}</h1>
        <h2>{h2[currentLanguage]}</h2>
        <h3>{h3[currentLanguage]}</h3>
      </div>
    </>
  )
}

export default withContext(withLanguage(Helmet))
