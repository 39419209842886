import { useEffect, useRef, useState } from 'react'
import Topbar from '../../components/Topbar'
import Trending from '../../components/Trending'
import { withLanguage } from '../../providers/LanguageContext'
import { withContext } from '../../providers/UserContext'
import AudioServices from '../../services/AudioServices'
import MovieServices from '../../services/MovieServices'
import SerieServices from '../../services/SerieServices'
import { firestore } from './../../services/FirebaseService'

import { Link } from 'react-router-dom'
import { routes } from './../../router'
import Env from '../../.env.js'
import { Grid, Paper } from '@material-ui/core'
import { Col, Container } from 'react-bootstrap'
import { Transition } from '@tailwindui/react'
import ReadMore from '../../components/ReadMore'
import InfiniteScroll from 'react-infinite-scroll-component'

type SearchProps = {
  region: any
  currentLanguage: 'es' | 'en'
  translate: any
}

type ResultType = {
  CC: string[]
  audio: string[]
  cast1: string
  cast2: string
  cast3: string
  cast4: string
  cast5: string
  cast6: string
  contentType: string
  descriptionEN: string
  descriptionES: string
  director1: string
  director2: string
  director3: string
  editorialEN: string
  editorialES: string
  id: string
  imageDCrew1: string
  imageDCrew2: string
  imageDCrew3: string
  imageDCrew4: string
  imageDCrew5: string
  imageDCrew6: string
  imageDCrew7: string
  imageDCrew8: string
  imageDCrew9: string
  imageDHigh: string
  imageDPeque: string
  imageDSeries: string
  imageMCrew1: string
  imageMCrew2: string
  imageMCrew3: string
  imageMCrew4: string
  imageMCrew5: string
  imageMCrew6: string
  imageMCrew7: string
  imageMCrew8: string
  imageMCrew9: string
  imageMDetails: string
  imageMHigh: string
  imageMPeque: string
  imageMSeries: string
  manifestURL: string
  minutes: string
  parts?: { chapters: []; number: number }[]
  position: number
  quality: string
  regions: string[]
  subtitles: []
  tag: string
  tagEN: string
  tagES: string
  titleEN: string
  titleES: string
  year: number
  seasons: []
}

const removeAccents = (string: string) =>
  string
    .toUpperCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

const Search: React.FC<SearchProps> = ({
  region,
  currentLanguage,
  translate,
}): JSX.Element => {
  const [trendingIds, setTrendingIds] = useState<any>()
  const [trendingContents, setTrendingContents] = useState<any>()

  useEffect(() => {
    firestore
      .collection('trending')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) =>
          setTrendingIds((prevData: any) =>
            prevData ? [...prevData, doc.data()] : [doc.data()]
          )
        )
      })
  }, [])

  useEffect(() => {
    if (trendingIds)
      trendingIds?.map((id: any) =>
        firestore
          .doc(`${id.category}/${id.id}`)
          .get()
          .then(
            (doc) =>
              doc.exists &&
              setTrendingContents((preContents: any) =>
                preContents
                  ? preContents.find((pCont: any) => pCont.id === doc.id)
                    ? preContents
                    : [...preContents, { id: doc.id, ...doc.data() }]
                  : [{ id: doc.id, ...doc.data() }]
              )
          )
      )
  }, [trendingIds])

  const [results, setResults] = useState<ResultType[] | null>(null)
  const [search, setSearch] = useState<string>('')

  const [allContents, setAllContents] = useState<ResultType[] | null>(null)

  useEffect(() => {
    const getAllContents = async () => {
      const movies = await MovieServices.getMovies(null, region)
      const series = await SerieServices.getSeries(null, region)
      const audios = await AudioServices.getAudios(null, region)

      setAllContents([
        ...movies.movies.map((item: any) => ({ ...item.data, id: item.id })),
        ...series.series.map((item: any) => ({ ...item.data, id: item.id })),
        ...audios.audios.map((item: any) => ({ ...item.data, id: item.id })),
      ])
    }

    getAllContents()
  }, [])

  useEffect(() => {
    if (search.length > 0) {
      const searchResults = allContents?.filter((content, index) => {
        const searchText = removeAccents(search).toUpperCase()

        const matches =
          removeAccents(content.titleES).toUpperCase().includes(searchText) ||
          removeAccents(content.titleEN).toUpperCase().includes(searchText)

        return matches
      })

      if (searchResults !== undefined) {
        setResults(searchResults)
      }
    }
  }, [search])

  const renderResults = search.length === 0 ? trendingContents : results

  const mostWantedText =
    search.length === 0
      ? currentLanguage === 'es'
        ? 'Los más buscados '
        : 'Most wanted'
      : ''

  const notFoundText =
    currentLanguage === 'es'
      ? 'No se encontraron resultados'
      : 'No results found'

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Topbar />

      <div
        style={{
          // maxWidth: '1440px',
          alignSelf: 'center',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <div className="container-list-movies">
          <Container fluid="lg" className="no-padding-responsive">
            <SearchBar
              {...{ search, setSearch, setResults, currentLanguage }}
            />

            <h2
              style={{
                color: 'white',
                fontSize: 26,
              }}
            >
              {mostWantedText}
            </h2>

            <div className="list-movies">
              <InfiniteScroll
                dataLength={renderResults?.length || 0}
                next={() => {}}
                hasMore={false}
                // hasMore={this.state.loaded && true}
                loader={<div></div>}
              >
                <Grid container>
                  {renderResults?.map((result: ResultType) => (
                    <Result
                      {...{ result, currentLanguage, translate }}
                      key={result.id}
                    />
                  ))}
                </Grid>
              </InfiniteScroll>
            </div>

            {search?.length > 0 && renderResults?.length === 0 ? (
              <h2
                style={{
                  color: 'white',
                  fontSize: 26,
                }}
              >
                {notFoundText}
              </h2>
            ) : null}
          </Container>
        </div>
      </div>
    </div>
  )
}

type SearchBarProps = {
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  setResults: React.Dispatch<React.SetStateAction<ResultType[] | null>>
  currentLanguage: 'en' | 'es'
}

const SearchBar: React.FC<SearchBarProps> = ({
  search,
  setSearch,
  setResults,
  currentLanguage,
}): JSX.Element => {
  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        margin: '50px 0',
      }}
      className="row"
    >
      <div
        style={{
          width: '100%',
          maxWidth: '1440px',
          borderBottomWidth: 2,
          borderColor: Env.colors.primary,
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          padding: '5px 5px',
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src="https://cdn-icons-png.flaticon.com/512/149/149852.png"
            style={{
              filter: 'brightness(0) invert(1)',
            }}
            width={30}
            height={30}
          />
        </div>

        <input
          value={search}
          style={{
            padding: '10px 15px',
            width: search && search.length > 0 ? '85%' : '95%',
            color: 'white',
            fontSize: '21px',
            background: 'transparent',
            outline: 'none',
          }}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={currentLanguage === 'es' ? 'Buscar' : 'Search'}
          autoCapitalize="sentences"
        />

        {search.length > 0 ? (
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              onClick={() => {
                setSearch('')
                setResults(null)
              }}
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/1828/1828747.png"
                style={{
                  filter: 'brightness(0) invert(1)',
                }}
                width={30}
                height={30}
              />
            </button>
          </div>
        ) : (
          <div
            style={{
              width: '30px',
              height: '30px',
            }}
          />
        )}
      </div>
    </div>
  )
}

type ResultProps = {
  result: ResultType
  currentLanguage: 'es' | 'en'
  translate: any
}

const Result: React.FC<ResultProps> = ({
  result,
  currentLanguage,
  translate,
}): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false)

  const category = currentLanguage === 'en' ? result.tagEN : result.tagES

  const hasCategory = category && category.length

  const getRouteType = () => {
    const { contentType, seasons } = result

    const movie = 'movie'
    const serie = 'serie'
    const short = 'short'

    if (contentType?.toLowerCase() === movie) {
      return 'movie'
    }

    if (contentType?.toLowerCase() === serie || seasons) {
      return 'serie'
    }

    if (contentType?.toLowerCase() === short) {
      return 'short'
    }

    return 'audio'
  }

  const link = `${getRouteType()}/${result.id}`

  return (
    <Grid item xs={12} sm={6} md={4} key={`list-movie-${result.id}`}>
      <Link className="text-decoration-none" to={link}>
        <div
          className="relative"
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Paper className="movie">
            <img src={result.imageDPeque} alt="serie list detail" />
            <Col
              className={` ${
                Env.env === 'selego' ? 'overlay-selego' : 'overlay'
              }`}
            >
              <div className="container-data">
                <div className="titleMovie">
                  {currentLanguage === 'en' ? result.titleEN : result.titleES}
                </div>
                <div className="author">{result.director1}</div>
                <div className="year">{result.year}</div>
                {hasCategory && <div className="list-category">{category}</div>}
              </div>
            </Col>
          </Paper>
          <Transition
            show={isHovered}
            enter="transition-opacity duration-300 ease-in-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500 ease-in-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Paper
              className="movie p-4 m-0 inset-0 transition-opacity"
              style={{ position: 'absolute' }}
            >
              <svg
                width="40"
                height="35"
                viewBox="0 0 50 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.66249 9.82031H0V35.6303H2.66249V9.82031Z"
                  fill={Env.colors.primary}
                />
                <path
                  d="M42.0248 33.0561H35.2248V25.8365H32.5623V33.0561H14.2443V36.0341C14.2443 37.9293 13.206 39.9731 11.0121 39.9731C8.88208 39.9731 7.55616 37.9452 7.55616 36.0341V8.9236H32.5676V9.82074H35.2301V8.56793V7.59647V1.32713C35.2301 0.594553 34.6337 0 33.8989 0H1.33125C0.596399 0 0 0.594553 0 1.32713V7.15056H2.66249V2.65425H32.5623V6.26935H6.21959C5.48474 6.26935 4.88834 6.8639 4.88834 7.59647V36.0341C4.88834 39.3307 7.28991 42.0752 10.4423 42.6326C10.4476 42.6326 10.4476 42.6326 10.453 42.6379C10.4796 42.6432 10.5009 42.6432 10.5275 42.6485C10.6926 42.6751 10.8523 42.7016 11.0174 42.7122C11.2198 42.7335 11.4168 42.7441 11.6138 42.7441H34.884H35.2887C39.0055 42.7441 42.0195 39.7395 42.0195 36.0341V33.0561H42.0248Z"
                  fill={Env.colors.primary}
                />
                <path
                  d="M25.4157 14.2793H12.918V16.9335H25.4157V14.2793Z"
                  fill={Env.colors.primary}
                />
                <path
                  d="M19.1646 22.291H13.8076V24.9453H19.1646V22.291Z"
                  fill={Env.colors.primary}
                />
                <path
                  d="M40.5123 8.32344L27.4922 18.8164L31.2685 23.4733L44.2886 12.9803L40.5123 8.32344Z"
                  fill={Env.colors.primary}
                />
                <path
                  d="M25.4325 20.502L23.1055 26.2033L29.1866 25.131L25.4325 20.502Z"
                  fill={Env.colors.primary}
                />
                <path
                  d="M48.8084 9.36486C50.1876 8.25007 50.4006 6.22753 49.2824 4.85263L49.2291 4.78893C48.686 4.12006 47.9192 3.70599 47.0618 3.61575C46.2045 3.5255 45.3685 3.775 44.6975 4.31647L43.4355 5.3357L41.9765 6.56728L45.9702 11.5201C46.8808 12.534 46.3057 13.7444 46.0607 14.1531L35.3682 22.599L37.0242 24.6799L47.8925 16.0907C47.9884 16.0164 48.0736 15.9262 48.1481 15.8253C48.9362 14.7265 49.9001 12.1253 48.1109 9.92756L48.8084 9.36486ZM47.1311 7.29985L45.869 8.31909L45.1182 7.39541L46.3802 6.37617C46.508 6.27531 46.6358 6.25408 46.7317 6.25408C46.753 6.25408 46.7743 6.25408 46.7903 6.25938C46.8755 6.27 47.0405 6.30716 47.163 6.46111L47.2109 6.52481C47.408 6.75838 47.3707 7.10875 47.1311 7.29985Z"
                  fill={Env.colors.primary}
                />
              </svg>
              <div className="title-author mt-3">
                {translate('aboutAuthors')}
              </div>
              <div className="content-author text-white">
                <ReadMore
                  text={
                    currentLanguage === 'en'
                      ? result.editorialEN
                      : result.editorialES
                  }
                />
              </div>
            </Paper>
          </Transition>
        </div>
      </Link>
    </Grid>
  )
}

export default withLanguage(withContext(Search))
