import axios from "axios";
import Env from "../../src/.env.js";

export const genericGet = (query: string, region?: string, params?: any) => {
  // TODO: update this to add params
  return axios.get(
    Env.backend_api_url + query + (region ? `&country_code=${region}` : "")
  );
};

export const genericPost = (query: string, region?: string, params?: any) => {
  // TODO: update this to add params
  return axios.get(
    Env.backend_api_url + query + (region ? `&country_code=${region}` : "")
  );
};
