import { useEffect } from "react";
import { withLanguage } from "../providers/LanguageContext";
import { withContext } from "../providers/UserContext";

function Analytics(props) {
  const { translate } = props;
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      /*      Adjust.initSdk({
        appToken: translate("adjust"),
        environment: "production",
      });
      Amplitude.getInstance().init(translate("amplitude"));*/
    }
  }, [translate]);
  return null;
}

export default withLanguage(withContext(Analytics));
