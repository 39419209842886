import React from "react";
import "./styles.scss";
import { Button, Row, Col, Container } from "react-bootstrap";
import { withLanguage } from "../../providers/LanguageContext";
import { withContext } from "../../providers/UserContext";
import { useModals } from "../../providers/ModalsContext";
import Env from "../../.env.js";

function BannerSubscription(props) {
  const { translate, category, user } = props;
  const { onToggle } = useModals();
  function onOpen() {
    if (user.auth) {
      onToggle("openSignup", true, {
        contentType: category,
      });
    } else {
      onToggle("openSignup");
    }
  }

  const glitch = Env.env === "glitch";
  const selego = Env.env === "selego";

  return (
    <div
      className={`banner-subscription ${
        (glitch && "black-color") || (selego && "banner-black selego-banner")
      }`}
    >
      <Container className="no-padding-responsive">
        <Row>
          <Col
            md="12"
            lg="9"
            className={`banner-text-content ${
              glitch ? "black-color" : "white-color"
            }`}
          >
            <span className="title-1Banner">{translate("bannerTitle")}</span>
            <p className="title-2Banner">{translate("bannerMsg")}</p>
          </Col>
          <Col className="col-button mt-4 mt-md-0" lg="3">
            <Button
              onClick={onOpen}
              className={`button-try-free ${
                (glitch && "black-background-color black-border-color") ||
                (selego && "button-try-free-selego")
              } primary-color`}
            >
              {translate("bannerBtn")}
            </Button>
            <span className="sub-cancel-label">
              {translate("bannerSubtitleBtn")}
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default withContext(withLanguage(BannerSubscription));
