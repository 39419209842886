import React from 'react'
import { withContext } from '../providers/UserContext'

function FAQQuestion({
  translate = () => {},
  id = '',
  ext = <React.Fragment />,
}) {
  return (
    <article className="lg:mx-4 mx-0 w-full lg:w-5/12 mb-10">
      <h3 className="fw-700 primary-color fw-500 fs-16 ls-3 my-3">
        {translate(`faqQ${id}`)}
      </h3>
      <p
        className="text-white fs-15"
        dangerouslySetInnerHTML={{
          __html: translate(`faqA${id}`),
        }}
      />
      {ext}
    </article>
  )
}

export default withContext(FAQQuestion)
