import { capitalize } from '@material-ui/core'

import { firestore } from '../services/FirebaseService'

import trackEvent from './trackEvent'
import Env from '../.env.js'

/**
 * * Log view_promotion analytics event
 * @param {monthlyPrice: number}
 * @param {annualPrice: number}
 * @param {content?: {title: string; type: string}} // if the subscriptions were viewed because the user tried to play a content
 */
export const logViewPromotion = ({
  monthlyPrice,
  annualPrice,
  content,
}: {
  monthlyPrice: number
  annualPrice: number
  content?: {
    title: string
    type: string
  }
}) => {
  trackEvent({
    type: 'view_promotion',
    params: {
      promotion_id: `${Env.env}-subscriptions-package-viewed`,
      promotion_name: `${capitalize(Env.env)} Subscriptions Package Viewed`,
      location_id: content
        ? `${content.type.toLowerCase()}-${content.title.toLowerCase()}`
        : 'menu',
      items: [
        {
          item_id: `${Env.env}-monthly-subscription`,
          item_brand: capitalize(Env.env),
          item_name: 'Monthly Subscription',
          price: monthlyPrice,
        },
        {
          item_id: `${Env.env}-annual-subscription`,
          item_brand: capitalize(Env.env),
          item_name: 'Anual Subscription',
          price: annualPrice,
        },
      ],
    },
  })
}

/**
 * * Log begin_checkout analytics event
 * @param {value: string}
 * @param {packageID: string}
 * @param {packageName: string}
 */
export const logBeginCheckout = ({
  value,
  currency,
  packageID,
  packageName,
}: {
  value: string
  currency: string
  packageID: string
  packageName: string
}) => {
  trackEvent({
    type: 'begin_checkout',
    params: {
      value: Number(value),
      currency,
      coupon: packageID,
      items: [
        {
          item_id: `${Env.env}-${packageName}`,
          item_brand: capitalize(Env.env),
          item_name: packageName,
          price: Number(value),
        },
      ],
    },
  })
}

/**
 * * Log purchase analytics event
 * @param {value: string}
 * @param {packageID: string}
 * @param {packageName: string}
 * @param {paymentPlatform: android | ios}
 */
export const logPurchase = ({
  value,
  currency,
  packageID,
  packageName,
  paymentPlatform,
  coupon,
}: {
  value: string
  currency: string
  packageID: string
  packageName: string
  paymentPlatform: 'stripe'
  coupon?: string
}) => {
  trackEvent({
    type: 'purchase',
    params: {
      transaction_id: '',
      affiliation: paymentPlatform,
      coupon: packageID,
      value: Number(value),
      currency,
      tax: 0,
      shipping: 0,
      items: [
        {
          item_id: `${Env.env}-${packageName}`,
          item_brand: capitalize(Env.env),
          item_name: packageName,
          price: Number(value),
        },
      ],
    },
  })
}

/**
 * * Log screen_view analytics event
 * @param {name: string}
 */
export const logScreenView = ({ name }: { name: string }) => {
  trackEvent({
    type: 'screen_view',
    params: {
      screen_name: name,
      screen_class: name,
    },
  })
}

/**
 * * Log sign_up analytics event
 * @param {method: string}
 */
export const logSignUp = ({ method }: { method: string }) => {
  trackEvent({
    type: 'sign_up',
    params: {
      method,
    },
  })
}

/**
 * * Log login analytics event
 * @param {method: string}
 */
export const logLogin = ({ method }: { method: string }) => {
  trackEvent({
    type: 'login',
    params: {
      method,
    },
  })
}

/**
 * * Log custom_event analytics event
 * @param {method: string}
 */
export const logCustomEvent = ({
  name,
  params,
}: {
  name: string
  params: any
}) => {
  trackEvent({
    type: 'custom_event',
    params: {
      name,
      params,
    },
  })
}

/**
 * * Add content to firestore viewedData collection
 * @param {method: string}
 */
export const logContentViewed = ({
  params,
}: {
  params: {
    uid: string
    country: string
    contentTitle: string
    contentId: string
  }
}) => {
  firestore.collection(`viewedData`).add({
    ...params,
    timestamp: new Date(),
  })
}
