import React from "react";
import SelegoIcon from "../../../src/resources/Logos/selego-main-logo.svg";

class SplashScreen extends React.Component {
  onClick(env) {
    sessionStorage.setItem("env", env);
    window.location.reload(false);
  }
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
          backgroundColor: "beige",
        }}
      >
        <svg
          className="cursor-pointer"
          onClick={() => this.onClick("glitch")}
          width="100"
          height="100"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M50 41.862C50 46.3379 46.3379 50 41.862 50H8.13802C3.66211 50 0 46.3379 0 41.862V8.13802C0 3.66211 3.66211 0 8.13802 0H41.862C46.3379 0 50 3.66211 50 8.13802V41.862Z"
            fill="black"
          />
          <path
            d="M25.2643 8.50912C19.4014 8.50912 14.6523 13.2581 14.6523 19.1211C14.6523 24.9841 19.4014 29.7331 25.2643 29.7331C31.1273 29.7331 35.8763 24.9841 35.8763 19.1211C35.8763 13.2581 31.1273 8.50912 25.2643 8.50912ZM25.2643 23.5632C22.8086 23.5632 20.8219 21.5765 20.8219 19.1208C20.8219 16.6651 22.8089 14.6784 25.2643 14.6784C27.7197 14.6784 29.7067 16.6651 29.7067 19.1208C29.7067 21.5765 27.7201 23.5632 25.2643 23.5632Z"
            fill="#F2F2F2"
          />
          <path
            d="M15.9851 30.8793C15.2377 30.8793 14.6436 31.534 14.7409 32.2765C15.4232 37.4757 19.8751 41.491 25.2598 41.491C30.6446 41.491 35.0964 37.4757 35.7787 32.2765C35.876 31.5336 35.2865 30.8793 34.5346 30.8793H30.8165C30.2361 30.8793 29.7256 31.2833 29.6003 31.8494C29.1592 33.836 27.3861 35.3217 25.2647 35.3217C23.1433 35.3217 21.3698 33.8364 20.9291 31.8494C20.8038 31.283 20.293 30.8793 19.7129 30.8793H15.9851Z"
            fill="#F2F2F2"
          />
        </svg>
        <img
          src={SelegoIcon}
          onClick={() => this.onClick("selego")}
          width={100}
          height={100}
          alt="selegoApp"
        />
        <svg
          className="cursor-pointer"
          onClick={() => this.onClick("myst")}
          width="100"
          height="100"
          viewBox="0 0 51 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M45.1607 0H5.02734C2.26592 0 0.0273438 2.23858 0.0273438 5V45C0.0273438 47.7614 2.26592 50 5.02735 50H45.1607C47.9221 50 50.1607 47.7614 50.1607 45V5C50.1607 2.23858 47.9221 0 45.1607 0Z"
            fill="black"
          />
          <path
            d="M25.0882 26.6425L18.3855 16.3105H7V34.6527H15.08V23.2372L22.7009 34.6527H26.9245L34.5914 23.2372V34.6527H42.8091V16.3105H31.8368L25.0882 26.6425Z"
            fill="white"
          />
        </svg>
      </div>
    );
  }
}

export default SplashScreen;
