import classNames from 'classnames'
import { useState } from 'react'
import { withContext } from '../../providers/UserContext'

import Env from '../../.env.js'

function CancelSubForm({ translate, uid, ...props }) {
  const [isChecked, setIsChecked] = useState({})
  const [isEmailOk, setIsEmailOk] = useState(false)
  const [otherText, setOtherText] = useState()

  const onSubmit = (e) => {
    e.preventDefault()
    props.onSubmit(
      `
    ${Object.keys(isChecked).reduce(
      (final, key) =>
        isChecked[key] ? `${final + translate(key)}<br/>` : final,
      ''
    )}
<br/>
    ${otherText}
<br/>
    ${isEmailOk ? translate('sendNewsEmail') : ''}
	    `
    )
  }

  const renderCheckboxOption = (key) => (
    <div className="py-2 my-2 flex items-center">
      <div
        className={classNames(
          'mx-2 border-2 primary-border-color w-4 h-4 rounded',
          {
            'primary-background-color': isChecked[key],
          }
        )}
        onClick={() =>
          setIsChecked((prevState) => {
            const newState = { ...prevState }
            newState[key] = !prevState[key]
            return newState
          })
        }
      />
      <span className="text-white">{translate(key)}</span>
    </div>
  )

  return (
    <>
      <h1 className="text-white font-bold my-4">{translate('noo!')}</h1>
      <p className="text-white">{translate('cancelSubStep1Msg')}</p>
      <form className="my-4" onSubmit={onSubmit}>
        <h5 className="primary-color font-bold">
          {translate('cancelFormTitle')}
        </h5>
        {[...Array(5).keys()].map((number) =>
          renderCheckboxOption(`cancelSubOption${number}`)
        )}
        <label className="text-white capitalize block">
          {translate('other')}:
        </label>
        <div className="lg:flex w-full justify-between items-end lg:pr-24 mb-3">
          <textarea
            className="rounded opacity-50 p-3 w-8/12"
            placeholder={translate('cancelReason')}
            rows="5"
            value={otherText}
            onChange={(e) => setOtherText(e.target.value)}
          />
          <button
            className={`${
              Env.env === 'glitch' ? '' : 'text-white'
            } primary-background-color px-5 py-2 tracking-widest`}
            type="submit"
          >
            {translate('next')}
          </button>
        </div>
        <div className="flex py-3 items-center">
          <div
            className={classNames('w-4 h-4 rounded-full border-2 mx-2', {
              'bg-white': isEmailOk,
            })}
            onClick={() => setIsEmailOk((prevState) => !prevState)}
          />
          <label className="text-white text-xs m-0">
            {translate('sendNewsEmail')}
          </label>
        </div>
      </form>
    </>
  )
}

export default withContext(CancelSubForm)
