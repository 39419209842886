import React, { useEffect } from 'react'
import RecoveryPasswordModal from '../tribes-media-ui/components/RecoveryPasswordModal'
import { withLanguage } from '../providers/LanguageContext'
import { withNotification } from '../providers/NotificationContext'
import { withContext } from '../providers/UserContext'
import UserServices from '../services/UserServices'
import trackEvent, { FIREBASE_EVENTS } from '../utils/trackEvent'
import { Logo } from './../resources/Logos'
import { logScreenView } from '../utils/analyticsEvents'

function Recovery(props) {
  const {
    translate,
    currentLanguage,
    runNotification,
    open,
    onClose,
    emailSent,
    onSentEmail,
    onReturnLogin,
  } = props
  const [loading, setLoading] = React.useState(false)

  async function onRecovery(values) {
    setLoading(true)
    try {
      await UserServices.resetPassword(values.email)

      logScreenView({
        name: 'Forgot Password View',
      })

      setLoading(false)
      onSentEmail()
    } catch (e) {
      console.log(e)
      setLoading(false)
      runNotification(e.message, 'error')
    }
  }

  useEffect(() => {
    if (open) {
      logScreenView({
        name: 'Forgot Password View',
      })
    }
  }, [open])

  return (
    <RecoveryPasswordModal
      open={open}
      disabled={loading}
      onClose={onClose}
      title={translate('bannerTitle')}
      recoveryLabel={translate('recoveryBtn')}
      language={currentLanguage}
      onSubmit={onRecovery}
      emailSent={emailSent}
      sentMessage={translate('recoveryMsg')}
      onReturnLogin={onReturnLogin}
      returnLoginLabel={translate('returnLoginBtn')}
      logo={Logo}
      color="secondary"
    />
  )
}

export default withNotification(withLanguage(withContext(Recovery)))
