import React, { useEffect, useState } from "react";
import promoImage from "../../resources/PromoImage.png";
import PromoImageMobile from "../../resources/PromoImageMobile.png";
import GlitchLogo from "../../resources/Logos/glitch-logo.svg";
import UserServices from "../../services/UserServices";
import { loadStripe } from "@stripe/stripe-js";
import Env from "../../.env.js";
import FormFlow from "../../tribes-media-ui/components/FlowSubs/pieces/FormFlow.js";
import { withLanguage } from "../../providers/LanguageContext.js";
import { withNotification } from "../../providers/NotificationContext.js";
import { withContext } from "../../providers/UserContext.js";
import { withModalsContext } from "../../providers/ModalsContext.js";
import "tailwindcss/tailwind.css";
import CountdownTimer from "../../components/CountDownTimer";
import { firestore } from "../../services/FirebaseService.js";

const PromotionScreen = (props: any) => {
  const [planSelected, setPlanSelected] = useState<string | null>(null);
  const [promoPrice, setPromoPrice] = useState<string | undefined>();
  const [realPrice, setrealPrice] = useState<string | undefined>();
  const [step, setStep] = useState(0);
  const [promoExpiration, setPromoExpiration] = useState<Date | undefined>();

  const [isLoading, setIsLoading] = useState(false);

  const { runNotification, translate, currentLanguage } = props;

  const buttonTextOptions = [
    translate("subscribeBtn"),
    translate("subscribeBtn2"),
  ];

  const getRegionPlans = async () => {
    try {
      const response = await UserServices.getPlans();

      if (response.length > 0) {
        const promoInfo = response[1][currentLanguage];
        setPromoPrice(promoInfo.split(" / ")[0]);

        const descriptionSplited = response[0].subtitleEN
          .split(",")[0]
          .split(" ");
        setrealPrice(descriptionSplited[descriptionSplited.length - 1]);

        setPlanSelected(response[0].id);
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const getPromotionExpiration = async () => {
    try {
      const detailsReq = await firestore
        .collection("promotions")
        .doc("1yearPromotion")
        .get();
      const expirationDate = detailsReq.get("expiration_date");
      if (expirationDate) {
        const { seconds, nanoseconds } = expirationDate;
        const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000);
        const date = new Date(milliseconds);
        setPromoExpiration(date);
      }
    } catch (error) {
      console.log("error to get promotion expiration date", error);
    }
  };

  // const stripePromise = loadStripe(
  //   "pk_live_51GzTsnIElqLJlzgQwVrMoLFspFepUnLH4Vd0bWjlmcDFrWbKAQuMJ9C4fXWK4cSVfQhN4m0Xy4YTicv8Z7usOlv60072XE1bBv"
  // );
  const stripePromise = loadStripe(Env.stripe_api_key);

  useEffect(() => {
    getRegionPlans();
    getPromotionExpiration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values: any) => {
    setIsLoading(true);
    onSignup(values)
      .then(async (customer_email) => {
        await goToStripe(customer_email);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  function onSignup(values: any) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        await UserServices.createUser(
          values.email,
          values.password,
          currentLanguage
        );

        resolve(values.email);
      } catch (e) {
        console.log(e);
        runNotification(e, "error");
        reject();
      }
    });
  }

  const goToStripe = async (customer_email: string) => {
    const myInit = {
      method: "POST",
      body: JSON.stringify({
        priceId: planSelected,
        coupon: "iQzoLhM3",
        customer_email,
        withoutTrial: true,
      }),
      headers: {
        "Content-Type": "text/plain",
      },
    };

    const stripe = await stripePromise;
    try {
      // "http://localhost:5001/glitch-development-adbad/us-central1/createCheckoutSession", DEV
      const response = await fetch(
        Env.cloudfunctions_url + "createCheckoutSession",
        myInit
      );
      const session = await response.json();
      if (stripe !== null) {
        stripe.redirectToCheckout({
          sessionId: session.id,
        });
      }
      setIsLoading(false);
    } catch (e) {
      console.log("-- error -- : ", e);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="position-relative bg-black text-white hidden lg:block"
        style={{ height: "100vh" }}
      >
        <div className="flex overflow-x-hidden absolute top-1 w-full">
          <div className="py-12 animate-marquee whitespace-nowrap ">
            <span
              className="text-5xl font-bold"
              style={{
                color: "transparent",
                WebkitTextStroke: "1px #FFFF00",
                textShadow: "none",
              }}
            >
              {translate("limitedOffer")} &gt; {translate("get80off")} &gt;{" "}
              {translate("limitedOffer")} &gt; {translate("get80off")}
              &gt; {translate("limitedOffer")} &gt; {translate("get80off")} &gt;
            </span>
          </div>

          <div className="absolute top-0 py-12 animate-marquee2 whitespace-nowrap ml-4">
            <span
              className="text-5xl font-bold"
              style={{
                color: "transparent",
                WebkitTextStroke: "1px #FFFF00",
                textShadow: "none",
              }}
            >
              {translate("limitedOffer")} &gt; {translate("get80off")} &gt;{" "}
              {translate("limitedOffer")} &gt; {translate("get80off")}
              &gt; {translate("limitedOffer")} &gt; {translate("get80off")}
            </span>
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-center align-items-center h-full justify-center position-absolute z-20"
          style={{ width: "75%" }}
        >
          {step === 0 ? (
            <>
              <img
                src={GlitchLogo}
                alt="Glitch logo"
                style={{
                  height: window.innerHeight * 0.2,
                  width: window.innerWidth * 0.25,
                }}
              />

              <p
                className="fw-700 fs-24 my-3 text-4xl text-center min-"
                style={{
                  color: "#FFFF00",
                  width: "75%",
                  marginBottom: "-2px !important",
                }}
              >
                {translate("promoTitle")}
              </p>
              <p
                className="fw-700 text-4xl text-center"
                style={{
                  color: "#FFFF00",
                  width: "75%",
                }}
              >
                {translate("promoTitle2")}
              </p>
              <p
                className="fs-18 my-6 text-2xl text-center"
                style={{ width: "75%" }}
              >
                {translate("promoDescription")}
              </p>

              <div className="text-white text-center ">
                <div className="text-6xl font-thin relative flex">
                  <span
                    className="text-5xl"
                    style={{
                      alignItems: "end",
                      display: "flex",
                      marginBottom: 2,
                    }}
                  >
                    {promoPrice}
                  </span>
                  <span
                    className="text-5xl  rotate-12"
                    style={{
                      alignItems: "end",
                      display: "flex",
                      marginBottom: 2,
                    }}
                  >
                    /
                  </span>
                  <span
                    className="text-3xl"
                    style={{
                      alignItems: "end",
                      display: "flex",
                    }}
                  >
                    {translate("oneYear")}
                  </span>
                </div>
                <div className="text-3xl line-through my-2">{realPrice}</div>
              </div>
              {promoExpiration && (
                <CountdownTimer
                  targetDate={promoExpiration.toString()}
                  translate={translate}
                />
              )}

              <span
                className="text-sm font-bold uppercase px-4 py-3 cursor-pointer"
                style={{ backgroundColor: "#FFFF00", color: "#000" }}
                onClick={() => setStep(1)}
              >
                {buttonTextOptions[Math.floor(Math.random() * 2)]}
              </span>
            </>
          ) : (
            <FormFlow
              colorFlow="color-glitch"
              tycFlow="tyc-glitch"
              btnFlow="btn-confirm-glitch"
              bestFlow="best-offer-glitch"
              bColor="b-glitch"
              stepChange={() => {}}
              final={onFinish}
              language={"ES"}
              disabled={isLoading}
              labels={{
                createAccount: translate("createAccount"),
                email: translate("email"),
                password: translate("password"),
                repeatPassword: translate("passwordFlow"),
                confirm: translate("confirmBtn"),
                join: translate("pricingJoin"),
              }}
            />
          )}
        </div>

        <img
          src={promoImage}
          alt="Promotion"
          className="position-absolute z-0"
          style={{
            right: 0,
            bottom: 0,
            height: "100%",
            objectFit: "cover",
            zIndex: 0,
          }}
        />
      </div>
      <div
        className="position-relative bg-black text-white lg:hidden"
        style={{ height: "100vh" }}
      >
        {step === 0 && (
          <>
            <div className="absolute left-0 top-0 h-full w-1/6 overflow-hidden">
              <div className="animate-marqueeV h-full flex flex-col justify-center items-center">
                <div className="py-12 whitespace-nowrap mr-4">
                  <span
                    className="text-2xl font-bold block"
                    style={{
                      color: "transparent",
                      WebkitTextStroke: "1px #FFFF00",
                      textShadow: "none",
                      writingMode: "vertical-rl",
                      transform: "rotate(180deg)",
                    }}
                  >
                    {translate("limitedOffer")} &gt; {translate("get80off")}
                    &gt; {translate("limitedOffer")} &gt;{" "}
                    {translate("get80off")} &gt; {translate("limitedOffer")}{" "}
                    &gt;
                    {translate("get80off")} &gt; {translate("limitedOffer")}{" "}
                    &gt; {translate("get80off")} &gt;
                  </span>
                </div>
              </div>

              <div className="animate-marqueeV2 h-full flex flex-col justify-center items-center">
                <div className="py-12 whitespace-nowrap mr-4">
                  <span
                    className="text-2xl font-bold block"
                    style={{
                      color: "transparent",
                      WebkitTextStroke: "1px #FFFF00",
                      textShadow: "none",
                      writingMode: "vertical-rl",
                      transform: "rotate(180deg)",
                    }}
                  >
                    {translate("limitedOffer")} &gt; {translate("get80off")}{" "}
                    &gt; {translate("limitedOffer")} &gt;
                    {translate("get80off")} &gt; {translate("limitedOffer")}{" "}
                    &gt; {translate("get80off")} &gt;{" "}
                    {translate("limitedOffer")} &gt; {translate("get80off")}{" "}
                    &gt;
                  </span>
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className="d-flex flex-column justify-content-center align-items-center h-full justify-center position-absolute z-10"
          style={{ width: "100%" }}
        >
          {step === 0 ? (
            <>
              <img
                src={GlitchLogo}
                alt="Glitch logo"
                height={200}
                width={200}
              />
              <p
                className="fw-700 mt-8 text-xl text-center z-0"
                style={{
                  color: "#FFFF00",
                  width: "60%",
                  marginBottom: "-2px !important",
                  zIndex: 0,
                }}
              >
                {translate("promoTitle")}
              </p>
              <p
                className="fw-700 text-xl mb-2 text-center"
                style={{
                  color: "#FFFF00",
                  width: "60%",
                }}
              >
                {translate("promoTitle2")}
              </p>
              <p
                className="fs-18 mb-2 text-sm text-center"
                style={{ width: "70%" }}
              >
                {translate("promoDescription")}
              </p>

              <div className="text-white text-center mt-1 mb-1 md:mt-0 md:mb-0">
                <div className="text-3xl font-bold relative flex">
                  <span
                    className="text-2xl"
                    style={{
                      alignItems: "end",
                      display: "flex",
                      marginBottom: 2,
                    }}
                  >
                    {promoPrice}
                  </span>
                  <span
                    className="text-2xl  rotate-12"
                    style={{
                      alignItems: "end",
                      display: "flex",
                      marginBottom: 2,
                    }}
                  >
                    /
                  </span>
                  <span className="text-lg mt-1 ml-1">
                    {translate("oneYear")}
                  </span>
                </div>
                <div className="text-lg line-through mb-1">{realPrice}</div>
              </div>

              {promoExpiration && (
                <CountdownTimer
                  targetDate={promoExpiration.toString()}
                  translate={translate}
                />
              )}

              <span
                className="text-xs font-bold uppercase px-4 py-3 cursor-pointer"
                style={{ backgroundColor: "#FFFF00", color: "#000" }}
                onClick={() => setStep(1)}
              >
                {buttonTextOptions[Math.floor(Math.random() * 2)]}
              </span>
            </>
          ) : (
            <FormFlow
              colorFlow="color-glitch"
              tycFlow="tyc-glitch"
              btnFlow="btn-confirm-glitch"
              bestFlow="best-offer-glitch"
              bColor="b-glitch"
              stepChange={() => {}}
              final={onFinish}
              language={currentLanguage}
              disabled={isLoading}
              labels={{
                createAccount: translate("createAccount"),
                email: translate("email"),
                password: translate("password"),
                repeatPassword: translate("passwordFlow"),
                confirm: translate("confirmBtn"),
                join: translate("pricingJoin"),
              }}
            />
          )}
        </div>

        {step === 0 && (
          <img
            src={PromoImageMobile}
            alt="Promotion"
            className="position-absolute z-0"
            style={{
              right: 0,
              bottom: 0,
              height: "100%",
              width: "100%",
              objectFit: "cover",
              zIndex: 1,
            }}
          />
        )}
      </div>
    </>
  );
};

export default withNotification(
  withModalsContext(withLanguage(withContext(PromotionScreen)))
);
