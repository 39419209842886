import { getBrandId } from "../utils/getAppVariantId";
import { genericGet } from "../utils/backendRequest";
import { endpointsQuery } from "../utils/endpoints";

const brandId = getBrandId();
const MovieServices = {
  getMovies: (lastVisible, region) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await genericGet(
          endpointsQuery.getMoviesList.replace("{brandId}", brandId),
          region
        );

        const movies = response.data.map((d) => ({ id: d.id, data: d }));

        const result = {
          movies,
          lastVisible: response.data[response.data.length - 1],
        };

        resolve(result);
      } catch (error) {
        reject(error.message);
      }
    });
  },
  getMovie: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await genericGet(
          endpointsQuery.getMovie.replace("{id}", id)
        );
        const data = response;
        resolve(data);
      } catch (error) {
        reject(error.message);
      }
    });
  },
};

export default MovieServices;
