import React from "react";
import "./styles.scss";
import Topbar from "../../components/Topbar/index";
import { Row, Col } from "react-bootstrap";
import { withContext } from "../../providers/UserContext";
import { Logo } from "./../../resources/Logos";

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="container-not-found">
        <Topbar />
        <div className="flex justify-center items-center flex-col m-5">
          <img src={Logo} alt="Logo" className="d-none d-md-block w-48 m-3" />
          <div className="sign-up success text-white text-center font-black text-lg">
            <Row className="text-2">
              <Col>
                <span>404</span>
              </Col>
            </Row>
            <Row className="text-2">
              <Col>
                <span>PAGE NOT FOUND</span>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default withContext(NotFound);
