import React from 'react';


const NotificationContext = React.createContext();
export const NotifyProvider = NotificationContext.Provider;
export const NotifyConsumer = NotificationContext.Consumer;

export function withNotification(Component) {
	const hocComponent = ({...props}) => (

		<NotifyConsumer>
            {propsContext => <Component {...props} 
            runNotification={propsContext.runNotification} 
			answer={propsContext.answer}
			setAnswer={propsContext.setAnswer} />}
		</NotifyConsumer>

	);

	return hocComponent;
}

export default NotificationContext;