import Env from "../../.env.js";
import GlitchLogo from "./glitch-logo.svg";
import SelegoLogo from "./selego-logo.svg";
import MystLogo from "./myst-logo.svg";
import GlitchMainLogo from "./glitch-main-logo.svg";
import SelegoMainLogo from "./selego-main-logo.svg";
import MystMainLogo from "./myst-main-logo.svg";
import GlitchMobileLogo from "./glitch-mobile-logo.svg";
import SelegoMobileLogo from "./selego-mobile-logo.svg";
import MystMobileLogo from "./myst-mobile-logo.svg";

const { env } = Env;

export const Logo =
  env === "glitch" ? GlitchLogo : env === "myst" ? MystLogo : SelegoLogo;
export const MainLogo =
  env === "glitch"
    ? GlitchMainLogo
    : env === "myst"
    ? MystMainLogo
    : SelegoMainLogo;
export const MobileLogo =
  env === "glitch"
    ? GlitchMobileLogo
    : env === "myst"
    ? MystMobileLogo
    : SelegoMobileLogo;
export const AlternativeLogos =
  env === "glitch"
    ? [MystMainLogo, SelegoMainLogo]
    : env === "myst"
    ? [GlitchMainLogo, SelegoMainLogo]
    : [GlitchMainLogo, MystMainLogo];
