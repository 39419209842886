import { analytics } from '../services/FirebaseService'

import { log } from './functions'

const ANALYTICS_EVENTS = [
  'view_promotion',
  'begin_checkout',
  'purchase',
  'screen_view',
  'sign_up',
  'login',
  'custom_event',
] as const

interface ANALYTICS_PROMOTION_ITEM {
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_id?: string
  item_list_id?: string
  item_list_name?: string
  item_location_id?: string
  item_name?: string
  item_variant?: string
  price?: number
  quantity?: number
}

type ANALYTICS_PROMOTIONS_ITEMS = ANALYTICS_PROMOTION_ITEM[]

interface ANALYTICS_EVENT_PARAMS {
  view_promotion: {
    promotion_id?: string
    promotion_name?: string
    creative_name?: string
    creative_slot?: string
    location_id?: string
    items?: ANALYTICS_PROMOTIONS_ITEMS
  }
  begin_checkout: {
    currency?: string
    value?: number
    coupon?: string
    items?: ANALYTICS_PROMOTIONS_ITEMS
  }
  purchase: {
    transaction_id?: string
    affiliation: 'stripe'
    coupon?: string
    currency?: string
    value?: number
    tax?: number
    shipping?: number
    items?: ANALYTICS_PROMOTIONS_ITEMS
  }
  screen_view: {
    name?: string
  }
  sign_up: {
    method: string
  }
  login: {
    method: string
  }
  custom_event: {
    [key: string]: any
  }
}

const isProduction = process.env.NODE_ENV === 'production'
const isDevelopment = process.env.NODE_ENV === 'development'

const trackEvent = async ({
  type,
  params,
}: {
  type: typeof ANALYTICS_EVENTS[number]
  params: any // ANALYTICS_EVENT_PARAMS[typeof ANALYTICS_EVENTS[number]]
}): Promise<void> => {
  if (isProduction) {
    const logError = (error: any) => {
      log('', 'info')
      log(error, 'error')
      log('', 'info')
    }

    const logEvent = () => {
      log('', 'info')
      log(type, 'success')
      log(params, 'success')
      log('', 'info')
    }

    try {
      if (isDevelopment) {
        logEvent()
      }

      switch (type) {
        case 'view_promotion':
          return analytics.logEvent(type, params)

        case 'begin_checkout':
          return analytics.logEvent(type, params)

        case 'purchase':
          return analytics.logEvent(type, params)

        case 'screen_view':
          return analytics.logEvent(type, params)

        case 'sign_up':
          return analytics.logEvent(type, params)

        case 'login':
          return analytics.logEvent(type, params)

        case 'custom_event':
          return analytics.logEvent(params.name, {
            params: params.params,
          })

        default:
          console.log(type, params)
      }
    } catch (error) {
      if (isDevelopment) {
        logError(error)
      }
    }
  }
}

export default trackEvent
