import firebase from "firebase/app";
import Env from "./../.env";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/analytics";

const {
  api_key,
  auth_domain,
  database_url,
  project_id,
  storage_bucket,
  messaging_sender_id,
  app_id,
  measurement_id,
} = Env.firebase;

const firebaseConfig = {
  apiKey: api_key,
  authDomain: auth_domain,
  databaseURL: database_url,
  projectId: project_id,
  storageBucket: storage_bucket,
  messagingSenderId: messaging_sender_id,
  appId: app_id,
  measurementId: measurement_id,
};

firebase.initializeApp(firebaseConfig);
export const firebaseInstance = firebase;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const database = firebase.database;
export const analytics = firebase.analytics();
export const persistence = {
  session: firebase.auth.Auth.Persistence.SESSION,
  local: firebase.auth.Auth.Persistence.LOCAL,
  none: firebase.auth.Auth.Persistence.NONE,
};
