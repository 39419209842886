import * as React from "react";
import { Container } from "react-bootstrap";
import { CastMemberDetails } from "../../tribes-media-ui/components";
import Topbar from "../../components/Topbar";
import { withLanguage } from "../../providers/LanguageContext";
import { withContext } from "../../providers/UserContext";
import CastServices from "../../services/CastServices";
import "./styles.scss";

function decodeName(name) {
  return name
    .replace(/\+/, " ")
    .replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
}
function CastDetail(props) {
  const { match, currentLanguage, translate } = props;
  const [details, setDetails] = React.useState(undefined);
  const params = match.params;
  React.useEffect(() => {
    async function fetchDetails() {
      try {
        const name = decodeName(params?.name);
        const details = await CastServices.getCastDetail(name);
        setDetails(details);
      } catch (e) {
        console.log(e);
        setDetails(null);
      }
    }
    fetchDetails();
  }, [params]);

  return (
    <div className="cast-detail">
      <Topbar />
      <Container>
        {details === undefined && <p>Loading...</p>}
        {details && (
          <CastMemberDetails
            castLabel={translate("castAndCrew")}
            filmographyLabel={translate("filmography")}
            emptyLabel={translate("noData")}
            name={details.name}
            imgSrc={details.imageURL}
            biography={details[`description${currentLanguage.toUpperCase()}`]}
            movies={details.movies}
          />
        )}
      </Container>
    </div>
  );
}

export default withLanguage(withContext(CastDetail));
