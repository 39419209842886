import React, { useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { EditSubModal, BasicModal } from '../tribes-media-ui/components'
import { withLanguage } from '../providers/LanguageContext'
import { useModals } from '../providers/ModalsContext'
import { withNotification } from '../providers/NotificationContext'
import { withContext } from '../providers/UserContext'
import UserServices from '../services/UserServices'
import { Logo } from './../resources/Logos'
import Env from './../.env.js'
import { logScreenView } from '../utils/analyticsEvents'

function EditSub(props) {
  const { region, translate, user, currentLanguage, plan, loadUserData } = props
  const [loading, setLoading] = React.useState(false)
  const [messages, setMessages] = React.useState({ success: '', error: '' })
  const [openCancel, setOpenCancel] = React.useState(false)
  const [planSelected, setPlanSelected] = React.useState({})
  const [plans, setPlans] = React.useState([])
  const history = useHistory()
  const { openEditSubscription, onToggle } = useModals()

  const onClose = useCallback(() => {
    if (history.location?.hash === '#openEditSubscription') {
      history.replace(history.location.pathname)
    }
    onToggle('openEditSubscription')
  }, [history, onToggle])

  React.useEffect(() => {
    if (openEditSubscription) {
      logScreenView({
        name: 'My Subscription View',
      })
    }
  }, [openEditSubscription])
  React.useEffect(() => {
    async function loadPlans() {
      try {
        const _plans = await UserServices.getPlans(region)
        const selected = _plans.find(
          (p) =>
            p.recurring !==
            user.data.subscription_type?.replace(/ly/gi, '').toLowerCase()
        )
        setPlanSelected(selected)
        setPlans(_plans)
      } catch (e) {
        console.log(e)
        toast.error('Something went wrong, contact our support')
      }
    }
    if (isEmpty(planSelected)) {
      loadPlans()
    }
  }, [planSelected, region, user])

  async function onSubmit(planSelected, token) {
    setLoading(true)
    try {
      await UserServices.subscribe(planSelected, token)
      setLoading(false)
      onToggle('openEditSubscription')
      loadUserData()
      history.push('/success')
    } catch (e) {
      setLoading(false)
      setMessages({
        error: translate('errorMsg'),
        success: '',
      })
    }
  }

  function getSubscriptionLabel() {
    const label = `${translate('subscription')} ${translate(
      user.data.subscription_type?.toLowerCase()
    )}`
    return label.toUpperCase()
  }

  async function onCancel() {
    onClose()
    history.push('/cancel-subscription')
  }

  function onCloseCancel() {
    setOpenCancel(false)
    onToggle('openEditSubscription')
  }

  const plansList = plans.map((plan) => ({
    label: plan[currentLanguage],
    discountLabel:
      plan?.recurring?.interval === 'year'
        ? translate('saveAnually')
        : undefined,
    id: plan.id,
  }))

  return (
    <>
      <EditSubModal
        open={openEditSubscription}
        onClose={onClose}
        onSubmit={onSubmit}
        apiKey={Env.stripe_api_key}
        language={currentLanguage}
        planSelected={planSelected?.id}
        disabled={loading}
        errorMessage={messages.error}
        successMessage={messages.success}
        color="secondary"
        logo={Logo}
        onCancel={onCancel}
        openPlans={() => {
          onToggle('openSignup', true)
        }}
        plans={plansList}
        list={[
          translate('subPromo1'),
          translate('subPromo2'),
          translate('subPromo3'),
        ]}
        labels={{
          title: translate('bannerTitle'),
          save: translate('saveBtn'),
          subscription: getSubscriptionLabel(),
          subscriptionBtn: planSelected && planSelected[currentLanguage],
          cancel: translate('cancelSubMsg'),
          cardNumber: translate('cardNumber'),
          discount:
            planSelected?.recurring?.interval === 'year'
              ? translate('saveAnually')
              : undefined,
          cardCVC: translate('cardCVC'),
          cardExpiration: translate('cardExpiration'),
          confirm: translate('confirmBtn'),
          unavailable:
            planSelected?.recurring?.interval === 'year'
              ? translate('alreadySubscribed')
              : undefined,
          plansSubTitle: translate('startSubscriptionTitle'),
          yesAgree: currentLanguage === 'es' ? 'Desuscribir' : 'Unsubscribe',
          plansDetails: (
            <p
              dangerouslySetInnerHTML={{
                __html: translate('startSubscriptionSubtitle'),
              }}
            />
          ),
          termsAndConditions: (
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://cdn3.themyst.com/TermsandConditions.html"
              >
                {translate('termsAndUse')}
              </a>
              {' & '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://cdn3.themyst.com/privacy.html"
              >
                {translate('privacyPolicy')}
              </a>
            </>
          ),
        }}
      />
      <BasicModal
        title={translate('cancelSubApple')}
        onClose={onCloseCancel}
        logo={Logo}
        open={openCancel}
      />
    </>
  )
}

export default withNotification(withLanguage(withContext(EditSub)))
