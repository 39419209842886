import React from "react";

const LanguageContext = React.createContext();
export const LangProvider = LanguageContext.Provider;
export const LangConsumer = LanguageContext.Consumer;

export function withLanguage(Component) {
  const hocComponent = ({ ...props }) => (
    <LangConsumer>
      {(propsContext) =>
        propsContext.region !== undefined ? (
          <Component
            {...props}
            region={propsContext.region}
            ip={propsContext.ip}
            languages={propsContext.languages}
          />
        ) : null
      }
    </LangConsumer>
  );

  return hocComponent;
}

export default LanguageContext;
