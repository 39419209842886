import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Topbar from "../../components/Topbar/index";
import { withContext } from "../../providers/UserContext";
import { withModalsContext } from "../../providers/ModalsContext";
import TribesLogo from "../../resources/tribes-logo.svg";
import TribesLogoSelego from "../../resources/tribes-logo-selego.svg";
import { AlternativeLogos, MainLogo } from "../../resources/Logos";
import FAQ from "../../components/FAQ";
import Env from "../../.env.js";
import "./styles.scss";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);

    this.onOpen = this.onOpen.bind(this);
  }

  onOpen() {
    const { onToggle } = this.props;
    if (this.props.user.auth) {
      onToggle("openSubscription", true);
    } else {
      onToggle("openSignup");
    }
  }

  renderSeparator() {
    return (
      <svg
        width="1"
        height="34"
        viewBox="0 0 1 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mx-4 my-1"
      >
        <line
          x1="0.5"
          y1="34"
          x2="0.500002"
          y2="-1.48619e-08"
          stroke={Env.env === "glitch" ? "gray" : Env.colors.primary}
        />
      </svg>
    );
  }

  getLink() {
    return Env.env === "glitch"
      ? ["https://themyst.com/", "https://selego.com/"]
      : Env.env === "myst"
      ? ["https://glitchanimation.com/", "https://selego.com/"]
      : ["https://glitchanimation.com/", "https://themyst.com/"];
  }

  renderContactUs() {
    const { translate } = this.props;
    return (
      <div className="d-flex align-items-center flex-wrap justify-content-center mx-4">
        <span
          className={`text-white my-1 mx-1 ${
            Env.env === "selego" && "text-selego"
          }`}
        >
          {translate("contactUs")}
        </span>
        <div className="d-flex items-center">
          <svg
            className="mx-1 my-1 primary-color"
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M19.428 13.504H0.572C0.256 13.504 0 13.248 0 12.936V0.576C0 0.536 0.004 0.496 0.012 0.456C0.02 0.416 0.028 0.388 0.04 0.36C0.04 0.356 0.044 0.352 0.044 0.348C0.076 0.28 0.108 0.232 0.144 0.192C0.196 0.132 0.26 0.084 0.328 0.052C0.396 0.024 0.468 0.004 0.548 0C0.556 0 0.568 0 0.576 0H19.424C19.432 0 19.444 0 19.452 0C19.532 0.004 19.604 0.02 19.672 0.052C19.74 0.084 19.804 0.132 19.86 0.192C19.924 0.264 19.968 0.348 19.988 0.444C19.988 0.444 19.988 0.444 19.988 0.448C19.996 0.492 20 0.532 20 0.576V12.936C20 13.248 19.744 13.504 19.428 13.504ZM1.14 12.364H18.856V1.776L10.36 8.732C10.148 8.904 9.848 8.904 9.636 8.732L1.14 1.776V12.364ZM2.168 1.14L10 7.556L17.832 1.14H2.168Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="20" height="13.504" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <a
            href={`mailto:${translate("tribesEmail").toLowerCase()}`}
            className={`text-white my-1 mx-1 ${
              Env.env === "selego" && "text-selego"
            }`}
          >
            {translate("tribesEmail")}
          </a>
        </div>
        {this.renderSeparator()}
        {this.renderSocialMedia()}
        {this.renderSeparator()}
        <span
          style={{
            color:
              Env.env === "glitch" || "selego" ? "gray" : Env.colors.primary,
          }}
          className="font-medium my-1 text-xs"
        >
          © 2021 TRIBES MEDIA BV.
        </span>
      </div>
    );
  }

  renderSocialMedia() {
    return (
      <div className="d-flex my-1">
        <a
          className="mx-2"
          href={`https://www.facebook.com/${Env.facebook}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            className="primary-color"
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.43 17.18H18.45V24.36H15.48V17.18H14V14.7H15.48V13.22C15.48 11.2 16.32 10 18.7 10H20.68V12.47H19.44C18.51 12.47 18.45 12.82 18.45 13.46V14.7H20.7L20.43 17.18Z"
              fill="currentColor"
            />
            <rect
              x="0.5"
              y="0.5"
              width="33.7"
              height="33.36"
              rx="3.5"
              stroke="currentColor"
            />
          </svg>
        </a>
        <a
          className="mx-2"
          href={`https://www.instagram.com/${Env.instagram}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            className="primary-color"
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.4299 16.8602C20.4299 15.4202 19.2499 14.2402 17.8099 14.2402C16.3699 14.2402 15.1899 15.4202 15.1899 16.8602C15.1899 18.3002 16.3699 19.4802 17.8099 19.4802C19.2599 19.4802 20.4299 18.3102 20.4299 16.8602Z"
              fill="currentColor"
            />
            <path
              d="M21.93 16.8599C21.93 19.1299 20.08 20.9799 17.81 20.9799C15.54 20.9799 13.69 19.1299 13.69 16.8599C13.69 16.5399 13.73 16.2299 13.8 15.9199H10.7V22.0899C10.7 23.2599 11.66 24.2099 12.82 24.2099H22.8C23.97 24.2099 24.92 23.2499 24.92 22.0899V15.9299H21.81C21.89 16.2299 21.93 16.5399 21.93 16.8599Z"
              fill="currentColor"
            />
            <path
              d="M22.8 10H12.82C11.66 10 10.7 10.95 10.7 12.12V14.43H14.5C15.25 13.41 16.45 12.75 17.81 12.75C19.17 12.75 20.37 13.42 21.12 14.43H24.92V12.12C24.92 10.95 23.97 10 22.8 10ZM23.74 12.57C23.74 12.8 23.55 12.99 23.32 12.99H22.36C22.13 12.99 21.94 12.8 21.94 12.57V11.61C21.94 11.38 22.13 11.19 22.36 11.19H23.32C23.55 11.19 23.74 11.38 23.74 11.61V12.57Z"
              fill="currentColor"
            />
            <rect
              x="1.19995"
              y="0.5"
              width="33.22"
              height="33.21"
              rx="3.5"
              stroke="currentColor"
            />
          </svg>
        </a>
      </div>
    );
  }

  render() {
    const { translate } = this.props;
    return (
      <>
        <Topbar />
        <section className="d-flex align-items-center flex-column py-10 lg:py-20 mx-10 lg:mx-24 lg:px-24 px-2 text-center container-lg">
          <h1
            className={`text-white fw-700 fs-32 my-3 text-uppercase ${
              Env.env === "selego" && "text-selego"
            }`}
          >
            {translate("welcome")}
          </h1>
          <h3
            className={`text-white fw-500 fs-16 ls-3 ${
              Env.env === "selego" && "text-selego"
            }`}
          >
            {translate("slogan")}
          </h3>
          <p
            className={`text-white fs-15 my-4 font-normal ${
              Env.env === "selego" && "text-selego"
            }`}
            dangerouslySetInnerHTML={{
              __html: translate("aboutUsTextPart1"),
            }}
          />

          <Button
            onClick={() => this.onOpen()}
            className={`primary-background-color border-0 rounded-0 text-uppercase py-2 ls-3 px-9 lg:px-12 my-4 ${
              (Env.env === "glitch" && "black-color") ||
              (Env.env === "selego" && "white-color")
            }`}
          >
            {translate("aboutUsBtn")}
          </Button>

          <video
            className="my-4"
            width="400px"
            src={translate("aboutUsVideo")}
            controls
          />
          {this.renderSocialMedia()}
          <div className="my-4">
            <p
              className={`text-white fs-15 my-4 ${
                Env.env === "selego" && "text-selego"
              }`}
            >
              {translate("aboutUsTextPart2")}
            </p>
            <p
              className={`text-white fs-16 mx-30 px-30 ls-3 fw-500 lh-2 ${
                Env.env === "selego" && "text-selego"
              }`}
              dangerouslySetInnerHTML={{
                __html: translate("aboutUsTextPart3"),
              }}
            />
          </div>
          {Env.env === "selego" ? (
            <img
              alt="Tribes logo"
              src={TribesLogoSelego}
              className="mt-5 mb-3"
            />
          ) : (
            <img alt="Tribes logo" src={TribesLogo} className="mt-5 mb-3" />
          )}
          <p
            className={`text-white fs-15 lg:px-24 lg:mx-24 no-underline-a ${
              Env.env === "selego" && "text-selego"
            }`}
            dangerouslySetInnerHTML={{ __html: translate("aboutUsTextPart4") }}
          />
          <div className="d-flex my-5">
            <Link to="/">
              <img alt={`${Env.env} logo`} src={MainLogo} className="mx-3" />
            </Link>
            <a
              href={this.getLink()[0]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="logo" src={AlternativeLogos[0]} className="mx-3" />
            </a>
            <a
              href={this.getLink()[1]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="logo" src={AlternativeLogos[1]} className="mx-3" />
            </a>
          </div>
          {this.renderContactUs()}
        </section>
        <section>
          <main className="position-relative">
            <div
              className="bgimg"
              style={{ backgroundImage: `url(${translate("welcomeIMG3")})` }}
            />
            <div className="d-flex flex-column bf-b-50 py-4 px-10 lg:px-24 lg:align-items-center">
              <h1 className="text-white fw-700 fs-32 my-3 text-uppercase lg:text-center">
                {translate("faq")}
              </h1>
              <FAQ
                title={translate("gettingStarted")}
                questions={[
                  "WhatIs",
                  "WhatDevices",
                  "HowSubscribe",
                  "Languages",
                  "HowDownload",
                  "Issues",
                ]}
                ext={true}
              />
              <FAQ
                title={translate("billingPayment")}
                questions={["HowPay", "HowChangeSub", "HowUninstall"]}
              />
              {this.renderContactUs()}
            </div>
          </main>
        </section>
      </>
    );
  }
}

export default withContext(withModalsContext(AboutUs));
