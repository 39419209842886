import { genericGet } from "../utils/backendRequest";
import { endpointsQuery } from "../utils/endpoints";
import { getBrandId } from "../utils/getAppVariantId";

const brandId = getBrandId();

export const TrendingService = {
  getTrendings: (region) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await genericGet(
          endpointsQuery.getTrendings.replace("{brandId}", brandId),
          region
        );

        const fixedData = response.data.map((t) => ({
          ...t,
          contentId: t.brand_content_id,
        }));

        resolve(fixedData);
      } catch (error) {
        reject(error.message);
      }
    });
  },
};
