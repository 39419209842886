import React, { useState, useContext, createContext, useEffect } from "react";
import AudioPlayer from "../tribes-media-ui/components/AudioPlayer";
import { auth, database, firebaseInstance } from "../services/FirebaseService";
import { useModals } from "./ModalsContext";
import UserServices from "../services/UserServices";
import trackEvent, { FIREBASE_EVENTS } from "../utils/trackEvent";
import { logContentViewed, logCustomEvent } from "../utils/analyticsEvents";

const initialState = {
  open: false,
  usingSlot: false,
  audio: {
    url: "",
    image: "",
    title: "",
    subTitle: "",
  },
};

const AudioPlayerContext = createContext(initialState);

export const AudioPlayerProvider = (props) => {
  const { children } = props;
  const { onToggle } = useModals();
  const [state, setState] = useState(initialState);
  const [devicesConnected] = useState(0);
  const [user, setUser] = useState();
  const [ubication, setUbication] = useState();
  let titlePlayViewed;

  useEffect(() => {
    async function getUbication() {
      try {
        auth.onAuthStateChanged((userAuth) => setUser(userAuth));
        const [location] = await Promise.all([
          UserServices.getClientLocation(),
        ]);
        setUbication(location);
      } catch (e) {
        console.log(e);
      }
    }
    getUbication();
  }, []);

  function onSelect(audio) {
    if (!state.usingSlot) {
      const uid = auth.currentUser.uid;
      const userActiveDevices = database().ref(`devices/${uid}`);
      userActiveDevices.ref.once("value", async (snapshot) => {
        const devicesCount = Object.keys(snapshot?.val() || {})?.length || 0;
        if (devicesCount < 5) {
          const devicesActive = {};
          const regexIp = `${ubication?.ip?.replace(/\./g, "")}`;
          devicesActive[regexIp] = devicesCount;
          userActiveDevices.set({ ...snapshot?.val(), ...devicesActive });
          setState({ audio, open: true, usingSlot: true });
          database().ref(`devices/${uid}/${regexIp}`).onDisconnect().remove();
        } else {
          onToggle("openSlotsFull");
        }
      });
    } else {
      setState({ audio, open: true, usingSlot: true });
    }
  }

  function onClose() {
    if (state.usingSlot) {
      const uid = auth.currentUser.uid;
      const userActiveDevices = database().ref(`devices/${uid}`);
      userActiveDevices.set(devicesConnected);
      userActiveDevices.onDisconnect().cancel();
    }
    setState({ ...state, open: false, usingSlot: false });
  }

  function onTimeChange(duration, currentTime) {
    const baseParams = {
      contentTitle: state.audio.subTitle,
      contentId: state.audio.id,
    };
    const tribesParams = {
      ...baseParams,
      uid: user.uid,
      created_at: firebaseInstance.firestore.FieldValue.serverTimestamp(),
      country: ubication.country_code,
    };
    if (
      duration < 1200 &&
      Math.floor(currentTime) === Math.floor(duration * 0.5) &&
      !titlePlayViewed
    ) {
      logCustomEvent({
        name: "title_play_viewed",
        params: baseParams,
      });

      logContentViewed({
        params: tribesParams,
      });

      titlePlayViewed = true;
    } else if (
      duration >= 1200 &&
      Math.floor(currentTime) === 600 &&
      !titlePlayViewed
    ) {
      logCustomEvent({
        name: "title_play_viewed",
        params: baseParams,
      });

      logContentViewed({
        params: tribesParams,
      });

      titlePlayViewed = true;
    }
  }

  return (
    <AudioPlayerContext.Provider value={{ onSelect, onClose }}>
      {children}
      <AudioPlayer
        open={state.open}
        onClose={onClose}
        url={state.audio.url}
        onTimeChange={onTimeChange}
        image={state.audio.image}
        title={state.audio.title}
        color="black"
        subTitle={state.audio.subTitle}
      />
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayer = () => {
  return useContext(AudioPlayerContext);
};
