interface EndpointsI {
  getMoviesList: string;
  getMovie: string;

  getSeriesList: string;
  getSerie: string;

  getAudiosList: string;
  getAudio: string;

  getShortsList: string;
  getShort: string;

  getTrendings: string;
}

export const endpointsQuery: EndpointsI = {
  getMoviesList: "/schedules?brand_id={brandId}&content_type=Movie",
  getMovie: "/schedules/{id}",

  getSeriesList: "/schedules?brand_id={brandId}&content_type=Serie",
  getSerie: "/schedules/{id}",

  getAudiosList: "/schedules?brand_id={brandId}&content_type=Audio",
  getAudio: "/schedules/{id}",

  getShortsList: "/schedules?brand_id={brandId}&content_type=Short",
  getShort: "/schedules/{id}",

  getTrendings: "/spotlights?brand_id={brandId}",
};
